import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'ljit-store-connecter';
import { withRouter } from 'react-router-dom';
import { renderSwitches } from 'common-lib/route-utils';
import ServiceModal from 'client-console/features/service-modal';
import { webHeadSourceActions } from 'client-console/controller';
import routes from 'client-console/routes';
import Header from './components/header';
import Footer from './components/footer';
import {
	webName,
	defaultWebNickname as initDefaultWebNickname,
	defaultWebDescription as initDefaultWebDescription,
	useDynamicHeadSource,
} from './utils';

const {
	initWebHeadSourceAction,
	setWebDefaultNicknameAction,
} = webHeadSourceActions;

const propTypes = {
	location: PropTypes.object,

	isContactModalVisible: PropTypes.bool,
	name: PropTypes.string,
	defaultNickname: PropTypes.string,
	nickname: PropTypes.string,
	defaultDescription: PropTypes.string,
	description: PropTypes.string,
	defaultStructuredData: PropTypes.string,
	structuredData: PropTypes.string,

	initWebHeadSourceAction: PropTypes.func,
	setWebDefaultNicknameAction: PropTypes.func,
};

const LayoutRoute = ({
	location,

	isContactModalVisible,
	name,
	defaultNickname,
	nickname,
	defaultDescription,
	description,
	defaultStructuredData,
	structuredData,

	initWebHeadSourceAction,
	setWebDefaultNicknameAction,
}) => {
	const { t } = useTranslation();

	useDynamicHeadSource({
		name,
		defaultNickname,
		nickname,
		defaultDescription,
		description,
		defaultStructuredData,
		structuredData,
	});

	useEffect(() => {
		initWebHeadSourceAction({
			name: webName,
			defaultNickname: initDefaultWebNickname,
			defaultDescription: initDefaultWebDescription,
		});
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		setWebDefaultNicknameAction(t(initDefaultWebNickname));
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [t]);

	return (
		<>
			<Header routeKey={location.pathname}/>
			{renderSwitches(routes)}
			<Footer/>

			<ServiceModal
				isVisible={isContactModalVisible}
			/>
		</>
	);
};

function mapStateToProps(state) {
	return {
		isContactModalVisible: state.contact.get('isVisible'),
		name: state.webHeadSource.get('name'),
		defaultNickname: state.webHeadSource.get('defaultNickname'),
		nickname: state.webHeadSource.get('nickname'),
		defaultDescription: state.webHeadSource.get('defaultDescription'),
		description: state.webHeadSource.get('description'),
		defaultStructuredData: state.webHeadSource.get('defaultStructuredData'),
		structuredData: state.webHeadSource.get('structuredData'),
	};
}

function mapDispatchToProps(dispatch) {
	return {
		initWebHeadSourceAction: (...args) => dispatch(initWebHeadSourceAction(...args)),
		setWebDefaultNicknameAction: (...args) => dispatch(setWebDefaultNicknameAction(...args)),
	};
}

LayoutRoute.propTypes = propTypes;

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(LayoutRoute));

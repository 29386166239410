import { getHeaderHeight } from 'client-console/layout-route/components/header/utils';

const scrollToTop = () => {
	window.scrollTo({ top: 0, behavior: 'smooth' });
};

const scrollToSection = sectionId => {
	const element = document.getElementById(sectionId);

	if (element) {
		const scrollToY = element.getBoundingClientRect().top + window.scrollY - getHeaderHeight();

		window.scrollTo({ top: scrollToY, behavior: 'smooth' });
	}
};

export {
	scrollToTop,
	scrollToSection,
};

import {
	START_FETCH_MARQUEES,
	FETCH_MARQUEES_SUCCESS,
	FETCH_MARQUEES_FAILED,
} from './action-types';

export function fetchMarqueesAction() {
	return {
		type: START_FETCH_MARQUEES,
	};
}

export function fetchMarqueesSuccessAction({ marquees }) {
	return {
		type: FETCH_MARQUEES_SUCCESS,
		marquees,
	};
}

export function fetchMarqueesFailedAction(error, errorMessage) {
	return {
		type: FETCH_MARQUEES_FAILED,
		error,
		errorMessage,
	};
}

import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import 'antd/dist/reset.css';
import 'ibears-react-components/dest/main.css';

import AuthRoute from './auth-route';
import I18nRoute from './i18n-route';
import LayoutRoute from './layout-route';
import { handleTrackPageView } from './lib/analytics-utils';
import './styling/layout/style.styl';

const App = () => {
	const location = useLocation();
	const _renderRoute = auth => (
		<I18nRoute
			render={() => <LayoutRoute auth={auth}/>}
		/>
	);

	useEffect(() => {
		handleTrackPageView(location.pathname + location.search);
	}, [location]);

	return (
		<AuthRoute
			render={auth => _renderRoute(auth)}
		/>
	);
};

export default App;

import {
	START_FETCH_LATEST_RECEIVE_LOGS,
	FETCH_LATEST_RECEIVE_LOGS_SUCCESS,
	FETCH_LATEST_RECEIVE_LOGS_FAILED,
} from './action-types';

export function fetchLatestReceiveLogsAction() {
	return {
		type: START_FETCH_LATEST_RECEIVE_LOGS,
	};
}

export function fetchLatestReceiveLogsSuccessAction(data) {
	return {
		type: FETCH_LATEST_RECEIVE_LOGS_SUCCESS,
		data,
	};
}

export function fetchLatestReceiveLogsFailedAction(error, errorMessage) {
	return {
		type: FETCH_LATEST_RECEIVE_LOGS_FAILED,
		error,
		errorMessage,
	};
}

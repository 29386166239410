/* eslint-disable camelcase */
const handleTrackPageView = url => {
	window.gtag('config', 'G-CC8J7E7CNB', { page_path: url });
};

const handleTrackEvent = (eventName, eventCategory = 'main', eventLabel = 'basic', value) => {
	if (!eventName) {
		return;
	}

	window.gtag('event', eventName, {
		event_category: eventCategory,
		event_label: eventLabel,
		value,
	});
};

export {
	handleTrackPageView,
	handleTrackEvent,
};

import RouteKeyEnums from 'client-console/routes/route-enums';

const {
	NOT_FOUND,
} = RouteKeyEnums;

const omitPaths = [
	NOT_FOUND,
];

function checkIsOmitPath(pathname = '') {
	const filteredOmitPaths = omitPaths
		.filter(omitPath => pathname.indexOf(omitPath) === 0);

	return filteredOmitPaths.length > 0;
}

export {
	// eslint-disable-next-line import/prefer-default-export
	checkIsOmitPath,
};

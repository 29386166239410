module.exports = {
	// ROLE
	ROLE_COULD_NOT_EDIT_SELF: {
		CODE: '888.001.403',
		MESSAGE: '不可编辑自己的角色',
	},
	ROLE_NOT_FOUND: {
		CODE: '888.001.404',
		MESSAGE: '角色不存在',
	},
	USER_ROLE_IS_BLOCKED: {
		CODE: '888.001.406',
		MESSAGE: '帐号角色已被停用，将立即登出系统',
	},
	ROLE_DUPLICATED: {
		CODE: '888.001.409',
		MESSAGE: '角色名称已存在',
	},
	ROLE_HAS_DESCENDANTS: {
		CODE: '888.001.410',
		MESSAGE: '此角色已有下级存在，无法进行删除',
	},
	USER_USING_ROLE: {
		CODE: '888.001.411',
		MESSAGE: '已有帐号套用此角色，无法删除',
	},
	ROLE_NOT_ALLOW_DELETE: {
		CODE: '888.001.412',
		MESSAGE: '系统管理员无法删除',
	},
	ROLE_OVER_LIMIT: {
		CODE: '888.001.413',
		MESSAGE: '角色數量超过限制',
	},
	INVALID_ROLE_REQUEST: {
		CODE: '888.001.422',
		MESSAGE: '输入格式错误',
	},
	// PLATFORM
	PLATFORM_NOT_FOUND: {
		CODE: '888.002.404',
		MESSAGE: '商户不存在',
	},
	PLATFORM_WALLET_NOT_FOUND: {
		CODE: '888.002.404',
		MESSAGE: '商户钱包不存在',
	},
	API_IP_NOT_FOUND: {
		CODE: '888.002.405',
		MESSAGE: 'IP不存在',
	},
	PLATFORM_DUPLICATED: {
		CODE: '888.002.409',
		MESSAGE: '商户名称已存在',
	},
	OTHER_PLATFORM_DUPLICATED: {
		CODE: '888.002.410',
		MESSAGE: '其他商户不可重复',
	},
	API_IP_DUPLICATED: {
		CODE: '888.002.411',
		MESSAGE: 'IP已存在',
	},
	PLATFORM_EXCHANGE_TRANSFER_IN_WALLET_NOT_FOUND: {
		CODE: '888.002.404',
		MESSAGE: '换币转入钱包不存在',
	},
	INVALID_PLATFORM_REQUEST: {
		CODE: '888.002.422',
		MESSAGE: '输入格式错误',
	},
	// Pledge Wallet
	PLEDGE_WALLET_NOT_FOUND: {
		CODE: '888.003.404',
		MESSAGE: '錢包不存在',
	},
	PLEDGE_WALLET_NAME_DUPLICATED: {
		CODE: '888.003.409',
		MESSAGE: '錢包已存在',
	},
	INVALID_PLEDGE_WALLET_REQUEST: {
		CODE: '888.003.422',
		MESSAGE: '输入格式错误',
	},
	EXCEED_AMOUNT_OF_TRX: {
		CODE: '888.003.423',
		MESSAGE: '超过当前最大可用TRX个数',
	},
	EXCEED_AMOUNT_OF_FROZEN_TRX: {
		CODE: '888.003.424',
		MESSAGE: '超过当前最大可解鎖TRX个数',
	},
	WAIT_WITHDRAW_TRX_IS_ZERO: {
		CODE: '888.003.425',
		MESSAGE: '目前待提取TRX为0！',
	},
	// EXCHANGE RATE LOG
	INVALID_EXCHANGE_RATE_LOG_REQUEST: {
		CODE: '888.005.422',
		MESSAGE: '输入格式错误',
	},
	// RECEIVE LOG
	RECEIVE_LOG_NOT_FOUND: {
		CODE: '888.006.404',
		MESSAGE: '专属能量租赁记录不存在',
	},
	RECEIVE_LOG_CURRENT_STATUS_NOT_ALLOW_BACK: {
		CODE: '888.006.405',
		MESSAGE: '当前状态无法执行退回操作',
	},
	RECEIVE_LOG_CURRENT_STATUS_NOT_ALLOW_RENT: {
		CODE: '888.006.406',
		MESSAGE: '当前状态无法执行租赁',
	},
	INVALID_RECEIVE_LOG_REQUEST: {
		CODE: '888.006.422',
		MESSAGE: '输入格式错误',
	},
	// LOGIN LOG
	INVALID_LOGIN_LOG_REQUEST: {
		CODE: '888.009.422',
		MESSAGE: '输入格式错误',
	},
	// AUTH
	INVALID_LOGIN_CREDENTIALS: {
		CODE: '888.010.401',
		MESSAGE: '帐号或密码错误',
	},
	GOOGLE_TOTP_IS_NOT_ENABLED: {
		CODE: '888.010.402',
		MESSAGE: '谷歌验证码未绑定',
	},
	INVALID_GOOGLE_TOTP_CREDENTIALS: {
		CODE: '888.010.406',
		MESSAGE: '谷歌验证码错误',
	},
	GOOGLE_TOTP_IS_ENABLED: {
		CODE: '888.010.407',
		MESSAGE: '谷歌验证已绑定',
	},
	SYSTEM_GOOGLE_TOTP_IS_NOT_ENABLED: {
		CODE: '888.010.408',
		MESSAGE: '系统谷歌验证码未绑定',
	},
	INVALID_AUTH_REQUEST: {
		CODE: '888.010.422',
		MESSAGE: '输入格式错误',
	},
	// USER
	USER_INVALID_OLD_PASSWORD_CREDENTIALS: {
		CODE: '888.012.401',
		MESSAGE: '旧登入密码错误',
	},
	USER_INVALID_PASSWORD_CREDENTIALS: {
		CODE: '888.012.402',
		MESSAGE: '登入密码错误',
	},
	USER_IS_FORBIDDEN: {
		CODE: '888.012.403',
		MESSAGE: '用户无此权限',
	},
	USER_NOT_FOUND: {
		CODE: '888.012.404',
		MESSAGE: '用户不存在',
	},
	USER_INVALID_PERMISSION: {
		CODE: '888.012.405',
		MESSAGE: '用户无此权限',
	},
	USER_IS_BLOCKED: {
		CODE: '888.012.406',
		MESSAGE: '帐户被停用',
	},
	USER_COULD_NOT_DELETE_SELF: {
		CODE: '888.012.407',
		MESSAGE: '使用者不可删除自己',
	},
	USERNAME_IS_DUPLICATED: {
		CODE: '888.012.409',
		MESSAGE: '帐号已存在',
	},
	NICKNAME_IS_DUPLICATED: {
		CODE: '888.012.410',
		MESSAGE: '暱稱不能重複',
	},
	IP_DUPLICATED: {
		CODE: '888.012.411',
		MESSAGE: 'IP已存在',
	},
	USER_PASSWORD_IS_UPDATED: {
		CODE: '888.012.412',
		MESSAGE: '登入密码已更新，请重新登入',
	},
	GOOGLE_TOTP_IS_DISABLED: {
		CODE: '888.012.413',
		MESSAGE: '谷歌验证码已解绑，请重新登入',
	},
	IP_NOT_FOUND: {
		CODE: '888.012.414',
		MESSAGE: 'IP不存在',
	},
	WITHOUT_LOGGED_IN: {
		CODE: '888.012.415',
		MESSAGE: '请先登入',
	},
	USER_IS_UPDATED: {
		CODE: '888.012.416',
		MESSAGE: '帐号已被修改，请重新登入',
	},
	USER_IS_ARCHIVED: {
		CODE: '888.012.417',
		MESSAGE: '帐号已被删除，请重新登入',
	},
	IP_NOT_MATCH_ANY_ONE: {
		CODE: '888.012.419',
		MESSAGE: 'IP 不在任一帐号的 IP 白名单内',
	},
	IP_NOT_IN_USER_IP_WHITELIST: {
		CODE: '888.012.420',
		MESSAGE: '不合法的 IP',
	},
	INVALID_USER_REQUEST: {
		CODE: '888.012.422',
		MESSAGE: '输入格式错误',
	},
	// MANAGEMENT LOG
	INVALID_MANAGEMENT_LOG_REQUEST: {
		CODE: '888.013.422',
		MESSAGE: '输入格式错误',
	},
	// SYSTEM_SETTING
	SYSTEM_SETTING_NOT_FOUND: {
		CODE: '888.014.404',
		MESSAGE: '系统参数不存在',
	},
	SYSTEM_GOOGLE_TOTP_BINDED: {
		CODE: '888.014.405',
		MESSAGE: '系统谷歌已绑定',
	},
	INVALID_SYSTEM_SETTING_REQUEST: {
		CODE: '888.014.422',
		MESSAGE: '输入格式错误',
	},
	// MANUAL RENT LOG
	MANUAL_RENT_LOG_NOT_FOUND: {
		CODE: '888.016.404',
		MESSAGE: '手动派发能量不存在',
	},
	INVALID_MANUAL_RENT_LOG_REQUEST: {
		CODE: '888.016.422',
		MESSAGE: '输入格式错误',
	},
	// PLATFORM WALLET COLLECT LOG
	INVALID_PLATFORM_WALLET_COLLECT_LOG_REQUEST: {
		CODE: '888.017.422',
		MESSAGE: '输入格式错误',
	},
	// PREPAID RENT LOG
	PREPAID_RENT_LOG_NOT_FOUND: {
		CODE: '888.019.404',
		MESSAGE: '预付能量租赁记录不存在',
	},
	INVALID_PREPAID_RENT_LOG_REQUEST: {
		CODE: '888.019.422',
		MESSAGE: '输入格式错误',
	},
	// PLATFORM_API_LOG
	PLATFORM_API_LOG_NOT_FOUND: {
		CODE: '888.020.404',
		MESSAGE: '接收日志不存在',
	},
	INVALID_PLATFORM_API_LOG_REQUEST: {
		CODE: '888.020.422',
		MESSAGE: '输入格式错误',
	},
	// EXCHANGE_TRANSFER_OUT_WALLET
	EXCHANGE_TRANSFER_OUT_WALLET_NOT_FOUND: {
		CODE: '888.021.404',
		MESSAGE: '换币转出钱包不存在',
	},
	EXCHANGE_TRANSFER_OUT_WALLET_NAME_DUPLICATED: {
		CODE: '888.021.409',
		MESSAGE: '钱包已存在',
	},
	INVALID_EXCHANGE_TRANSFER_OUT_WALLET_REQUEST: {
		CODE: '888.021.422',
		MESSAGE: '输入格式错误',
	},
	// EXCHANGE_LOG
	EXCHANGE_LOG_NOT_FOUND: {
		CODE: '888.022.404',
		MESSAGE: '换币交易记录不存在',
	},
	INVALID_EXCHANGE_LOG_REQUEST: {
		CODE: '888.022.422',
		MESSAGE: '输入格式错误',
	},
	// EXCHANGE_TRANSFER_OUT_WALLET_LOG
	INVALID_EXCHANGE_TRANSFER_OUT_WALLET_LOG: {
		CODE: '888.023.422',
		MESSAGE: '输入格式错误',
	},
	// EXCHANGE_SETTING
	EXCHANGE_SETTING_NOT_FOUND: {
		CODE: '888.024.404',
		MESSAGE: '兌換功能记录不存在',
	},
	INVALID_EXCHANGE_SETTING: {
		CODE: '888.024.422',
		MESSAGE: '输入格式错误',
	},
	// MARQUEE
	INVALID_MARQUEE_NOT_FOUND: {
		CODE: '888.025.404',
		MESSAGE: '跑马灯不存在',
	},
	INVALID_MARQUEE_REQUEST: {
		CODE: '888.025.422',
		MESSAGE: '输入格式错误',
	},
	// CS_SETTING
	CS_SETTING_NOT_FOUND: {
		CODE: '888.026.404',
		MESSAGE: '在線客服记录不存在',
	},
	INVALID_CS_SETTING: {
		CODE: '888.026.422',
		MESSAGE: '输入格式错误',
	},

	// COMMON
	DAY_RANGE_EXCEEDED: {
		CODE: '888.000.601',
		MESSAGE: '选择的日期区间超过限制',
	},
};

const RouteKeyEnums = {
	MAIN: '/',
	ENERGY_EXCHANGE: '/energy-exchange',
	USDT_EXCHANGE: '/usdt-exchange',
	LATEST_RECORDS: '/latest-records',
	ABOUT: '/about',
	FAQ: '/FAQ',
	NOT_FOUND: '/not-found',
};

export { RouteKeyEnums as default };

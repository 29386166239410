import { Map } from 'immutable';
import { LoadingStatusEnum } from 'common-lib/enums';
import { actionTypes } from 'client-console/controller';

const {
	START_FETCH_LATEST_RECEIVE_LOGS,
	FETCH_LATEST_RECEIVE_LOGS_SUCCESS,
	FETCH_LATEST_RECEIVE_LOGS_FAILED,
} = actionTypes;

const {
	NONE,
	LOADING,
	SUCCESS,
	FAILED,
} = LoadingStatusEnum;

const initialState = Map({
	dataMap: Map(),

	loadingStatus: NONE,
	loadingStatusMessage: '',
});

export default function latestReceiveLogs(state = initialState, action) {
	switch (action.type) {
		case START_FETCH_LATEST_RECEIVE_LOGS:
			return state.set('loadingStatus', LOADING);

		case FETCH_LATEST_RECEIVE_LOGS_SUCCESS: {
			const { data } = action;

			return state
				.set('dataMap', Map(data))
				.set('loadingStatus', SUCCESS);
		}

		case FETCH_LATEST_RECEIVE_LOGS_FAILED:
			return state
				.set('loadingStatus', FAILED)
				.set('loadingStatusMessage', action.errorMessage);

		default:
			return state;
	}
}

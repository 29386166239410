import { DetailsTypeEnum } from 'management-console/lib/enums';
import {
	START_FETCH_BASIC_ROLES,
	FETCH_BASIC_ROLES_SUCCESS,
	FETCH_BASIC_ROLES_FAILED,

	START_FETCH_ROLES,
	FETCH_ROLES_SUCCESS,
	FETCH_ROLES_FAILED,

	START_CREATE_ROLE,
	CREATE_ROLE_SUCCESS,
	CREATE_ROLE_FAILED,

	START_UPDATE_ROLE,
	UPDATE_ROLE_SUCCESS,
	UPDATE_ROLE_FAILED,

	START_DELETE_ROLE,
	DELETE_ROLE_SUCCESS,
	DELETE_ROLE_FAILED,

	START_EDIT_ROLE_PERMISSIONS,
	EDIT_ROLE_PERMISSIONS_SUCCESS,
	EDIT_ROLE_PERMISSIONS_FAILED,
} from './action-types';

const { BASIC, FULL } = DetailsTypeEnum;

export function fetchBasicRolesAction(queries = {}) {
	return {
		type: START_FETCH_BASIC_ROLES,
		queries: { ...queries, details: BASIC },
	};
}

export function fetchBasicRolesSuccessAction({
	basicRoles,
}) {
	return {
		type: FETCH_BASIC_ROLES_SUCCESS,
		basicRoles,
	};
}

export function fetchBasicRolesFailedAction(error, errorMessage) {
	return {
		type: FETCH_BASIC_ROLES_FAILED,
		error,
		errorMessage,
	};
}

export function fetchRolesAction(queries = {}) {
	return {
		type: START_FETCH_ROLES,
		queries: { ...queries, details: FULL },
	};
}

export function fetchRolesSuccessAction({
	queries,
	roles,
	numOfItems,
	numOfPages,
}) {
	return {
		type: FETCH_ROLES_SUCCESS,
		queries,
		roles,
		numOfItems,
		numOfPages,
	};
}

export function fetchRolesFailedAction(error, errorMessage) {
	return {
		type: FETCH_ROLES_FAILED,
		error,
		errorMessage,
	};
}

export function createRoleAction(data) {
	return {
		type: START_CREATE_ROLE,
		data,
	};
}

export function createRoleSuccessAction() {
	return {
		type: CREATE_ROLE_SUCCESS,
	};
}

export function createRoleFailedAction(error, errorMessage) {
	return {
		type: CREATE_ROLE_FAILED,
		error,
		errorMessage,
	};
}

export function updateRoleAction(roleId, data) {
	return {
		type: START_UPDATE_ROLE,
		roleId,
		data,
	};
}

export function updateRoleSuccessAction() {
	return {
		type: UPDATE_ROLE_SUCCESS,
	};
}

export function updateRoleFailedAction(error, errorMessage) {
	return {
		type: UPDATE_ROLE_FAILED,
		error,
		errorMessage,
	};
}

export function deleteRoleAction(roleId) {
	return {
		type: START_DELETE_ROLE,
		roleId,
	};
}

export function deleteRoleSuccessAction() {
	return {
		type: DELETE_ROLE_SUCCESS,
	};
}

export function deleteRoleFailedAction(error, errorMessage) {
	return {
		type: DELETE_ROLE_FAILED,
		error,
		errorMessage,
	};
}

export function editRolePermissionsAction(roleId, permissions, totp) {
	return {
		type: START_EDIT_ROLE_PERMISSIONS,
		roleId,
		permissions,
		totp,
	};
}

export function editRolePermissionsSuccessAction() {
	return {
		type: EDIT_ROLE_PERMISSIONS_SUCCESS,
	};
}

export function editRolePermissionsFailedAction(error, errorMessage) {
	return {
		type: EDIT_ROLE_PERMISSIONS_FAILED,
		error,
		errorMessage,
	};
}

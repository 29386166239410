// System error
export const SHOW_RELOGIN_MESSAGE = 'SHOW_RELOGIN_MESSAGE';

// Cached page
export const SET_CURRENT_CACHED_PAGE = 'SET_CURRENT_CACHED_PAGE';
export const STOP_CURRENT_CACHED_PAGE = 'STOP_CURRENT_CACHED_PAGE';
export const DELETE_CURRENT_CACHED_PAGE = 'DELETE_CURRENT_CACHED_PAGE';
export const UPDATE_CACHED_DATA = 'UPDATE_CACHED_DATA';

// Auth
export const START_HEARTBEAT = 'START_HEARTBEAT';
export const STOP_HEARTBEAT = 'STOP_HEARTBEAT';

export const START_LOGIN = 'START_LOGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILED = 'LOGIN_FAILED';

export const START_LOGOUT = 'START_LOGOUT';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILED = 'LOGOUT_FAILED';

export const START_CHECK_AUTH = 'START_CHECK_AUTH';
export const CHECK_AUTH_SUCCESS = 'CHECK_AUTH_SUCCESS';
export const CHECK_AUTH_FAILED = 'CHECK_AUTH_FAILED';

export const START_LOGIN_VIA_GOOGLE_TOTP = 'START_LOGIN_VIA_GOOGLE_TOTP';
export const LOGIN_VIA_GOOGLE_TOTP_SUCCESS = 'LOGIN_VIA_GOOGLE_TOTP_SUCCESS';
export const LOGIN_VIA_GOOGLE_TOTP_FAILED = 'LOGIN_VIA_GOOGLE_TOTP_FAILED';

// Me
export const START_FETCH_ME_ACTION = 'START_FETCH_ME_ACTION';
export const SET_ME = 'SET_ME';

export const START_RESET_PASSWORD = 'START_RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILED = 'RESET_PASSWORD_FAILED';

export const START_BIND_GOOGLE_AUTH = 'START_BIND_GOOGLE_AUTH';
export const BIND_GOOGLE_AUTH_SUCCESS = 'BIND_GOOGLE_AUTH_SUCCESS';
export const BIND_GOOGLE_AUTH_FAILED = 'BIND_GOOGLE_AUTH_FAILED';

export const START_UNBIND_GOOGLE_AUTH = 'START_UNBIND_GOOGLE_AUTH';
export const UNBIND_GOOGLE_AUTH_SUCCESS = 'UNBIND_GOOGLE_AUTH_SUCCESS';
export const UNBIND_GOOGLE_AUTH_FAILED = 'UNBIND_GOOGLE_AUTH_FAILED';

// Validate Client Ip
export const START_VALIDATE_CLIENT_IP = 'START_VALIDATE_CLIENT_IP';
export const VALIDATE_CLIENT_IP_SUCCESS = 'VALIDATE_CLIENT_IP_SUCCESS';
export const VALIDATE_CLIENT_IP_FAILED = 'VALIDATE_CLIENT_IP_FAILED';

// User
export const START_FETCH_USERS = 'START_FETCH_USERS';
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export const FETCH_USERS_FAILED = 'FETCH_USERS_FAILED';

export const START_CREATE_USER = 'START_CREATE_USER';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CREATE_USER_FAILED = 'CREATE_USER_FAILED';

export const START_UPDATE_USER = 'START_UPDATE_USER';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILED = 'UPDATE_USER_FAILED';

export const START_DELETE_USER = 'START_DELETE_USER';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAILED = 'DELETE_USER_FAILED';

export const START_USER_UNBIND_GOOGLE = 'START_USER_UNBIND_GOOGLE';
export const USER_UNBIND_GOOGLE_SUCCESS = 'USER_UNBIND_GOOGLE_SUCCESS';
export const USER_UNBIND_GOOGLE_FAILED = 'USER_UNBIND_GOOGLE_FAILED';

// User / ip whitelist
export const START_FETCH_USER_IP_WHITELISTS = 'START_FETCH_USER_IP_WHITELISTS';
export const FETCH_USER_IP_WHITELISTS_SUCCESS = 'FETCH_USER_IP_WHITELISTS_SUCCESS';
export const FETCH_USER_IP_WHITELISTS_FAILED = 'FETCH_USER_IP_WHITELISTS_FAILED';

export const START_CREATE_USER_IP_WHITELISTS = 'START_CREATE_USER_IP_WHITELISTS';
export const CREATE_USER_IP_WHITELISTS_SUCCESS = 'CREATE_USER_IP_WHITELISTS_SUCCESS';
export const CREATE_USER_IP_WHITELISTS_FAILED = 'CREATE_USER_IP_WHITELISTS_FAILED';

export const START_DELETE_USER_IP_WHITELISTS = 'START_DELETE_USER_IP_WHITELISTS';
export const DELETE_USER_IP_WHITELISTS_SUCCESS = 'DELETE_USER_IP_WHITELISTS_SUCCESS';
export const DELETE_USER_IP_WHITELISTS_FAILED = 'DELETE_USER_IP_WHITELISTS_FAILED';

// Role
export const START_FETCH_BASIC_ROLES = 'START_FETCH_BASIC_ROLES';
export const FETCH_BASIC_ROLES_SUCCESS = 'FETCH_BASIC_ROLES_SUCCESS';
export const FETCH_BASIC_ROLES_FAILED = 'FETCH_BASIC_ROLES_FAILED';

export const START_FETCH_ROLES = 'START_FETCH_ROLES';
export const FETCH_ROLES_SUCCESS = 'FETCH_ROLES_SUCCESS';
export const FETCH_ROLES_FAILED = 'FETCH_ROLES_FAILED';

export const START_CREATE_ROLE = 'START_CREATE_ROLE';
export const CREATE_ROLE_SUCCESS = 'CREATE_ROLE_SUCCESS';
export const CREATE_ROLE_FAILED = 'CREATE_ROLE_FAILED';

export const START_UPDATE_ROLE = 'START_UPDATE_ROLE';
export const UPDATE_ROLE_SUCCESS = 'UPDATE_ROLE_SUCCESS';
export const UPDATE_ROLE_FAILED = 'UPDATE_ROLE_FAILED';

export const START_DELETE_ROLE = 'START_DELETE_ROLE';
export const DELETE_ROLE_SUCCESS = 'DELETE_ROLE_SUCCESS';
export const DELETE_ROLE_FAILED = 'DELETE_ROLE_FAILED';

export const START_EDIT_ROLE_PERMISSIONS = 'START_EDIT_ROLE_PERMISSIONS';
export const EDIT_ROLE_PERMISSIONS_SUCCESS = 'EDIT_ROLE_PERMISSIONS_SUCCESS';
export const EDIT_ROLE_PERMISSIONS_FAILED = 'EDIT_ROLE_PERMISSIONS_FAILED';

// Role/Platform
export const START_EDIT_ROLE_PLATFORMS = 'START_EDIT_ROLE_PLATFORMS';
export const EDIT_ROLE_PLATFORMS_SUCCESS = 'EDIT_ROLE_PLATFORMS_SUCCESS';
export const EDIT_ROLE_PLATFORMS_FAILED = 'EDIT_ROLE_PLATFORMS_FAILED';

// System Google Totp
export const START_FETCH_SYSTEM_TOTP = 'START_FETCH_SYSTEM_TOTP';
export const FETCH_SYSTEM_TOTP_SUCCESS = 'FETCH_SYSTEM_TOTP_SUCCESS';
export const FETCH_SYSTEM_TOTP_FAILED = 'FETCH_SYSTEM_TOTP_FAILED';

export const START_BIND_SYSTEM_TOTP = 'START_BIND_SYSTEM_TOTP';
export const BIND_SYSTEM_TOTP_SUCCESS = 'BIND_SYSTEM_TOTP_SUCCESS';
export const BIND_SYSTEM_TOTP_FAILED = 'BIND_SYSTEM_TOTP_FAILED';

export const START_UNBIND_SYSTEM_TOTP = 'START_UNBIND_SYSTEM_TOTP';
export const UNBIND_SYSTEM_TOTP_SUCCESS = 'UNBIND_SYSTEM_TOTP_SUCCESS';
export const UNBIND_SYSTEM_TOTP_FAILED = 'UNBIND_SYSTEM_TOTP_FAILED';

// Ip Whitelist
export const START_FETCH_IP_WHITELISTS = 'START_FETCH_IP_WHITELISTS';
export const FETCH_IP_WHITELISTS_SUCCESS = 'FETCH_IP_WHITELISTS_SUCCESS';
export const FETCH_IP_WHITELISTS_FAILED = 'FETCH_IP_WHITELISTS_FAILED';

export const START_CREATE_IP_WHITELISTS = 'START_CREATE_IP_WHITELISTS';
export const CREATE_IP_WHITELISTS_SUCCESS = 'CREATE_IP_WHITELISTS_SUCCESS';
export const CREATE_IP_WHITELISTS_FAILED = 'CREATE_IP_WHITELISTS_FAILED';

export const START_DELETE_IP_WHITELISTS = 'START_DELETE_IP_WHITELISTS';
export const DELETE_IP_WHITELISTS_SUCCESS = 'DELETE_IP_WHITELISTS_SUCCESS';
export const DELETE_IP_WHITELISTS_FAILED = 'DELETE_IP_WHITELISTS_FAILED';

// Management Log
export const START_FETCH_MANAGEMENT_LOGS = 'START_FETCH_MANAGEMENT_LOGS';
export const FETCH_MANAGEMENT_LOGS_SUCCESS = 'FETCH_MANAGEMENT_LOGS_SUCCESS';
export const FETCH_MANAGEMENT_LOGS_FAILED = 'FETCH_MANAGEMENT_LOGS_FAILED';

// System Settings
export const START_FETCH_SYSTEM_SETTINGS = 'START_FETCH_SYSTEM_SETTINGS';
export const FETCH_SYSTEM_SETTINGS_SUCCESS = 'FETCH_SYSTEM_SETTINGS_SUCCESS';
export const FETCH_SYSTEM_SETTINGS_FAILED = 'FETCH_SYSTEM_SETTINGS_FAILED';

export const START_UPDATE_SYSTEM_SETTINGS = 'START_UPDATE_SYSTEM_SETTINGS';
export const UPDATE_SYSTEM_SETTINGS_SUCCESS = 'UPDATE_SYSTEM_SETTINGS_SUCCESS';
export const UPDATE_SYSTEM_SETTINGS_FAILED = 'UPDATE_SYSTEM_SETTINGS_FAILED';

export const START_FETCH_EARLY_ENERGY_LIMIT = 'START_FETCH_EARLY_ENERGY_LIMIT';
export const FETCH_EARLY_ENERGY_LIMIT_SUCCESS = 'FETCH_EARLY_ENERGY_LIMIT_SUCCESS';
export const FETCH_EARLY_ENERGY_LIMIT_FAILED = 'FETCH_EARLY_ENERGY_LIMIT_FAILED';

export const START_UPDATE_EARLY_ENERGY_LIMIT = 'START_UPDATE_EARLY_ENERGY_LIMIT';
export const UPDATE_EARLY_ENERGY_LIMIT_SUCCESS = 'UPDATE_EARLY_ENERGY_LIMIT_SUCCESS';
export const UPDATE_EARLY_ENERGY_LIMIT_FAILED = 'UPDATE_EARLY_ENERGY_LIMIT_FAILED';

export const START_FETCH_COLLECT_WALLET = 'START_FETCH_COLLECT_WALLET';
export const FETCH_COLLECT_WALLET_SUCCESS = 'FETCH_COLLECT_WALLET_SUCCESS';
export const FETCH_COLLECT_WALLET_FAILED = 'FETCH_COLLECT_WALLET_FAILED';

export const START_UPDATE_COLLECT_WALLET = 'START_UPDATE_COLLECT_WALLET';
export const UPDATE_COLLECT_WALLET_SUCCESS = 'UPDATE_COLLECT_WALLET_SUCCESS';
export const UPDATE_COLLECT_WALLET_FAILED = 'UPDATE_COLLECT_WALLET_FAILED';

// Login Log
export const START_FETCH_LOGIN_LOGS = 'START_FETCH_LOGIN_LOGS';
export const FETCH_LOGIN_LOGS_SUCCESS = 'FETCH_LOGIN_LOGS_SUCCESS';
export const FETCH_LOGIN_LOGS_FAILED = 'FETCH_LOGIN_LOGS_FAILED';

// Transaction Info
export const START_FETCH_PLEDGE_WALLET_LOGS = 'START_FETCH_PLEDGE_WALLET_LOGS';
export const FETCH_PLEDGE_WALLET_LOGS_SUCCESS = 'FETCH_PLEDGE_WALLET_LOGS_SUCCESS';
export const FETCH_PLEDGE_WALLET_LOGS_FAILED = 'FETCH_PLEDGE_WALLET_LOGS_FAILED';

// Energy Rental Management
export const START_FETCH_BASIC_PLEDGE_WALLETS = 'START_FETCH_BASIC_PLEDGE_WALLETS';
export const FETCH_BASIC_PLEDGE_WALLETS_SUCCESS = 'FETCH_BASIC_PLEDGE_WALLETS_SUCCESS';
export const FETCH_BASIC_PLEDGE_WALLETS_FAILED = 'FETCH_BASIC_PLEDGE_WALLETS_FAILED';

export const START_FETCH_PLEDGE_WALLETS = 'START_FETCH_PLEDGE_WALLETS';
export const FETCH_PLEDGE_WALLETS_SUCCESS = 'FETCH_PLEDGE_WALLETS_SUCCESS';
export const FETCH_PLEDGE_WALLETS_FAILED = 'FETCH_PLEDGE_WALLETS_FAILED';

export const START_CREATE_PLEDGE_WALLET = 'START_CREATE_PLEDGE_WALLET';
export const CREATE_PLEDGE_WALLET_SUCCESS = 'CREATE_PLEDGE_WALLET_SUCCESS';
export const CREATE_PLEDGE_WALLET_FAILED = 'CREATE_PLEDGE_WALLET_FAILED';

export const START_UPDATE_PLEDGE_WALLET = 'START_UPDATE_PLEDGE_WALLET';
export const UPDATE_PLEDGE_WALLET_SUCCESS = 'UPDATE_PLEDGE_WALLET_SUCCESS';
export const UPDATE_PLEDGE_WALLET_FAILED = 'UPDATE_PLEDGE_WALLET_FAILED';

export const START_FREEZE_PLEDGE_WALLET = 'START_FREEZE_PLEDGE_WALLET';
export const FREEZE_PLEDGE_WALLET_SUCCESS = 'FREEZE_PLEDGE_WALLET_SUCCESS';
export const FREEZE_PLEDGE_WALLET_FAILED = 'FREEZE_PLEDGE_WALLET_FAILED';

export const START_UNFREZZE_PLEDGE_WALLET = 'START_UNFREZZE_PLEDGE_WALLET';
export const UNFREZZE_PLEDGE_WALLET_SUCCESS = 'UNFREZZE_PLEDGE_WALLET_SUCCESS';
export const UNFREZZE_PLEDGE_WALLET_FAILED = 'UNFREZZE_PLEDGE_WALLET_FAILED';

export const START_WITHDRAW_PLEDGE_WALLET = 'START_WITHDRAW_PLEDGE_WALLET';
export const WITHDRAW_PLEDGE_WALLET_SUCCESS = 'WITHDRAW_PLEDGE_WALLET_SUCCESS';
export const WITHDRAW_PLEDGE_WALLET_FAILED = 'WITHDRAW_PLEDGE_WALLET_FAILED';

export const START_RECYCLE_PLEDGE_WALLET = 'START_RECYCLE_PLEDGE_WALLET';
export const RECYCLE_PLEDGE_WALLET_SUCCESS = 'RECYCLE_PLEDGE_WALLET_SUCCESS';
export const RECYCLE_PLEDGE_WALLET_FAILED = 'RECYCLE_PLEDGE_WALLET_FAILED';

// Exchange Rate
export const START_FETCH_EXCHANGE_RATE = 'START_FETCH_EXCHANGE_RATE';
export const FETCH_EXCHANGE_RATE_SUCCESS = 'FETCH_EXCHANGE_RATE_SUCCESS';
export const FETCH_EXCHANGE_RATE_FAILED = 'FETCH_EXCHANGE_RATE_FAILED';

// Platform
export const START_FETCH_PLATFORMS = 'START_FETCH_PLATFORMS';
export const FETCH_PLATFORMS_SUCCESS = 'FETCH_PLATFORMS_SUCCESS';
export const FETCH_PLATFORMS_FAILED = 'FETCH_PLATFORMS_FAILED';

export const START_CREATE_PLATFORM = 'START_CREATE_PLATFORM';
export const CREATE_PLATFORM_SUCCESS = 'CREATE_PLATFORM_SUCCESS';
export const CREATE_PLATFORM_FAILED = 'CREATE_PLATFORMS_FAILED';

export const START_UPDATE_PLATFORM = 'START_UPDATE_PLATFORM';
export const UPDATE_PLATFORM_SUCCESS = 'UPDATE_PLATFORM_SUCCESS';
export const UPDATE_PLATFORM_FAILED = 'UPDATE_PLATFORMS_FAILED';

export const START_FETCH_PLATFORM_DETAIL = 'START_FETCH_PLATFORM_DETAIL';
export const FETCH_PLATFORM_DETAIL_SUCCESS = 'FETCH_PLATFORM_DETAIL_SUCCESS';
export const FETCH_PLATFORM_DETAIL_FAILED = 'FETCH_PLATFORM_DETAIL_FAILED';

export const START_REGENERATE_API_SECRET = 'START_REGENERATE_API_SECRET';
export const REGENERATE_API_SECRET_SUCCESS = 'REGENERATE_API_SECRET_SUCCESS';
export const REGENERATE_API_SECRET_FAILED = 'REGENERATE_API_SECRET_FAILED';

export const START_UPDATE_RESOURCE_PROVIDER = 'START_UPDATE_RESOURCE_PROVIDER';
export const UPDATE_RESOURCE_PROVIDER_SUCCESS = 'UPDATE_RESOURCE_PROVIDER_SUCCESS';
export const UPDATE_RESOURCE_PROVIDER_FAILED = 'UPDATE_RESOURCE_PROVIDER_FAILED';

export const START_UPDATE_EXCHANGE_WALLETS = 'START_UPDATE_EXCHANGE_WALLETS';
export const UPDATE_EXCHANGE_WALLETS_SUCCESS = 'UPDATE_EXCHANGE_WALLETS_SUCCESS';
export const UPDATE_EXCHANGE_WALLETS_FAILED = 'UPDATE_EXCHANGE_WALLETS_FAILED';

// Platform Wallet
export const START_FETCH_PLATFORM_WALLETS = 'START_FETCH_PLATFORM_WALLETS';
export const FETCH_PLATFORM_WALLETS_SUCCESS = 'FETCH_PLATFORM_WALLETS_SUCCESS';
export const FETCH_PLATFORM_WALLETS_FAILED = 'FETCH_PLATFORM_WALLETS_FAILED';

export const START_CREATE_PLATFORM_WALLET = 'START_CREATE_PLATFORM_WALLET';
export const CREATE_PLATFORM_WALLET_SUCCESS = 'CREATE_PLATFORM_WALLET_SUCCESS';
export const CREATE_PLATFORM_WALLET_FAILED = 'CREATE_PLATFORM_WALLET_FAILED';

export const START_UPDATE_PLATFORM_WALLET = 'START_UPDATE_PLATFORM_WALLET';
export const UPDATE_PLATFORM_WALLET_SUCCESS = 'UPDATE_PLATFORM_WALLET_SUCCESS';
export const UPDATE_PLATFORM_WALLET_FAILED = 'UPDATE_PLATFORM_WALLET_FAILED';

// Platform / api ip whitelist
export const START_FETCH_API_IP_WHITELISTS = 'START_FETCH_API_IP_WHITELISTS';
export const FETCH_API_IP_WHITELISTS_SUCCESS = 'FETCH_API_IP_WHITELISTS_SUCCESS';
export const FETCH_API_IP_WHITELISTS_FAILED = 'FETCH_API_IP_WHITELISTS_FAILED';

export const START_CREATE_API_IP_WHITELISTS = 'START_CREATE_API_IP_WHITELISTS';
export const CREATE_API_IP_WHITELISTS_SUCCESS = 'CREATE_API_IP_WHITELISTS_SUCCESS';
export const CREATE_API_IP_WHITELISTS_FAILED = 'CREATE_API_IP_WHITELISTS_FAILED';

export const START_DELETE_API_IP_WHITELISTS = 'START_DELETE_API_IP_WHITELISTS';
export const DELETE_API_IP_WHITELISTS_SUCCESS = 'DELETE_API_IP_WHITELISTS_SUCCESS';
export const DELETE_API_IP_WHITELISTS_FAILED = 'DELETE_API_IP_WHITELISTS_FAILED';

// Receive Log
export const START_FETCH_RECEIVE_LOGS = 'START_FETCH_RECEIVE_LOGS';
export const FETCH_RECEIVE_LOGS_SUCCESS = 'FETCH_RECEIVE_LOGS_SUCCESS';
export const FETCH_RECEIVE_LOGS_FAILED = 'FETCH_RECEIVE_LOGS_FAILED';

export const START_BACK_VIRTUAL_CURRENCY = 'START_BACK_VIRTUAL_CURRENCY';
export const BACK_VIRTUAL_CURRENCY_SUCCESS = 'BACK_VIRTUAL_CURRENCY_SUCCESS';
export const BACK_VIRTUAL_CURRENCY_FAILED = 'BACK_VIRTUAL_CURRENCY_FAILED';

export const START_RENT_RECEIVE_LOG = 'START_RENT_RECEIVE_LOG';
export const RENT_RECEIVE_LOG_SUCCESS = 'RENT_RECEIVE_LOG_SUCCESS';
export const RENT_RECEIVE_LOG_FAILED = 'RENT_RECEIVE_LOG_FAILED';

// Manual Rent Log
export const START_FETCH_MANUAL_RENT_LOG = 'START_FETCH_MANUAL_RENT_LOG';
export const FETCH_MANUAL_RENT_LOG_SUCCESS = 'FETCH_MANUAL_RENT_LOG_SUCCESS';
export const FETCH_MANUAL_RENT_LOG_FAILED = 'FETCH_MANUAL_RENT_LOG_FAILED';

export const START_CREATE_MANUAL_ENERGY = 'START_CREATE_MANUAL_ENERGY';
export const CREATE_MANUAL_ENERGY_SUCCESS = 'CREATE_MANUAL_ENERGY_SUCCESS';
export const CREATE_MANUAL_ENERGY_FAILED = 'CREATE_MANUAL_ENERGY_FAILED';

// Collect Rent Wallet Log
export const START_FETCH_COLLECT_RENT_WALLET_LOGS = 'START_FETCH_COLLECT_RENT_WALLET_LOGS';
export const FETCH_COLLECT_RENT_WALLET_LOGS_SUCCESS = 'FETCH_COLLECT_RENT_WALLET_LOGS_SUCCESS';
export const FETCH_COLLECT_RENT_WALLET_LOGS_FAILED = 'FETCH_COLLECT_RENT_WALLET_LOGS_FAILED';

// Exchange Rate Log
export const START_FETCH_EXCHANGE_RATE_LOGS = 'START_FETCH_EXCHANGE_RATE_LOGS';
export const FETCH_EXCHANGE_RATE_LOGS_SUCCESS = 'FETCH_EXCHANGE_RATE_LOGS_SUCCESS';
export const FETCH_EXCHANGE_RATE_LOGS_FAILED = 'FETCH_EXCHANGE_RATE_LOGS_FAILED';

// Super Representative
export const START_FETCH_SUPER_REPRESENTATIVES = 'START_FETCH_SUPER_REPRESENTATIVES';
export const FETCH_SUPER_REPRESENTATIVES_SUCCESS = 'FETCH_SUPER_REPRESENTATIVES_SUCCESS';
export const FETCH_SUPER_REPRESENTATIVES_FAILED = 'FETCH_SUPER_REPRESENTATIVES_FAILED';

export const START_FETCH_SUPER_REPRESENTATIVE = 'START_FETCH_SUPER_REPRESENTATIVE';
export const FETCH_SUPER_REPRESENTATIVE_SUCCESS = 'FETCH_SUPER_REPRESENTATIVE_SUCCESS';
export const FETCH_SUPER_REPRESENTATIVE_FAILED = 'FETCH_SUPER_REPRESENTATIVE_FAILED';

export const START_UPDATE_SUPER_REPRESENTATIVE = 'START_UPDATE_SUPER_REPRESENTATIVE';
export const UPDATE_SUPER_REPRESENTATIVE_SUCCESS = 'UPDATE_SUPER_REPRESENTATIVE_SUCCESS';
export const UPDATE_SUPER_REPRESENTATIVE_FAILED = 'UPDATE_SUPER_REPRESENTATIVE_FAILED';

// Prepaid Rent Log
export const START_FETCH_PREPAID_RENT_LOGS = 'START_FETCH_PREPAID_RENT_LOGS';
export const FETCH_PREPAID_RENT_LOGS_SUCCESS = 'FETCH_PREPAID_RENT_LOGS_SUCCESS';
export const FETCH_PREPAID_RENT_LOGS_FAILED = 'FETCH_PREPAID_RENT_LOGS_FAILED';

// Platform Api Log
export const START_FETCH_PLATFORM_API_LOGS = 'START_FETCH_PLATFORM_API_LOGS';
export const FETCH_PLATFORM_API_LOGS_SUCCESS = 'FETCH_PLATFORM_API_LOGS_SUCCESS';
export const FETCH_PLATFORM_API_LOGS_FAILED = 'FETCH_PLATFORM_API_LOGS_FAILED';

export const START_FETCH_PLATFORM_API_LOG_DETAIL = 'START_FETCH_PLATFORM_API_LOG_DETAIL';
export const FETCH_PLATFORM_API_LOG_DETAIL_SUCCESS = 'FETCH_PLATFORM_API_LOG_DETAIL_SUCCESS';
export const FETCH_PLATFORM_API_LOG_DETAIL_FAILED = 'FETCH_PLATFORM_API_LOG_DETAIL_FAILED';

// Exchange Log
export const START_FETCH_EXCHANGE_LOGS = 'START_FETCH_EXCHANGE_LOGS';
export const FETCH_EXCHANGE_LOGS_SUCCESS = 'FETCH_EXCHANGE_LOGS_SUCCESS';
export const FETCH_EXCHANGE_LOGS_FAILED = 'FETCH_EXCHANGE_LOGS_FAILED';

// Exchange Transfer In Wallet
export const START_FETCH_EXCHANGE_TRANSFER_IN_WALLETS = 'START_FETCH_EXCHANGE_TRANSFER_IN_WALLETS';
export const FETCH_EXCHANGE_TRANSFER_IN_WALLETS_SUCCESS = 'FETCH_EXCHANGE_TRANSFER_IN_WALLETS_SUCCESS';
export const FETCH_EXCHANGE_TRANSFER_IN_WALLETS_FAILED = 'FETCH_EXCHANGE_TRANSFER_IN_WALLETS_FAILED';

export const START_CREATE_EXCHANGE_TRANSFER_IN_WALLET = 'START_CREATE_EXCHANGE_TRANSFER_IN_WALLET';
export const CREATE_EXCHANGE_TRANSFER_IN_WALLET_SUCCESS = 'CREATE_EXCHANGE_TRANSFER_IN_WALLET_SUCCESS';
export const CREATE_EXCHANGE_TRANSFER_IN_WALLET_FAILED = 'CREATE_EXCHANGE_TRANSFER_IN_WALLET_FAILED';

export const START_UPDATE_EXCHANGE_TRANSFER_IN_WALLET = 'START_UPDATE_EXCHANGE_TRANSFER_IN_WALLET';
export const UPDATE_EXCHANGE_TRANSFER_IN_WALLET_SUCCESS = 'UPDATE_EXCHANGE_TRANSFER_IN_WALLET_SUCCESS';
export const UPDATE_EXCHANGE_TRANSFER_IN_WALLET_FAILED = 'UPDATE_EXCHANGE_TRANSFER_IN_WALLET_FAILED';

// Exchange Transfer Out Wallet
export const START_FETCH_BASIC_EXCHANGE_TRANSFER_OUT_WALLETS = 'START_FETCH_BASIC_EXCHANGE_TRANSFER_OUT_WALLETS';
export const FETCH_BASIC_EXCHANGE_TRANSFER_OUT_WALLETS_SUCCESS = 'FETCH_BASIC_EXCHANGE_TRANSFER_OUT_WALLETS_SUCCESS';
export const FETCH_BASIC_EXCHANGE_TRANSFER_OUT_WALLETS_FAILED = 'FETCH_BASIC_EXCHANGE_TRANSFER_OUT_WALLETS_FAILED';

export const START_FETCH_EXCHANGE_TRANSFER_OUT_WALLETS = 'START_FETCH_EXCHANGE_TRANSFER_OUT_WALLETS';
export const FETCH_EXCHANGE_TRANSFER_OUT_WALLETS_SUCCESS = 'FETCH_EXCHANGE_TRANSFER_OUT_WALLETS_SUCCESS';
export const FETCH_EXCHANGE_TRANSFER_OUT_WALLETS_FAILED = 'FETCH_EXCHANGE_TRANSFER_OUT_WALLETS_FAILED';

export const START_CREATE_EXCHANGE_TRANSFER_OUT_WALLET = 'START_CREATE_EXCHANGE_TRANSFER_OUT_WALLET';
export const CREATE_EXCHANGE_TRANSFER_OUT_WALLET_SUCCESS = 'CREATE_EXCHANGE_TRANSFER_OUT_WALLET_SUCCESS';
export const CREATE_EXCHANGE_TRANSFER_OUT_WALLET_FAILED = 'UPDATE_EXCHANGE_TRANSFER_OUT_WALLET_FAILED';

export const START_UPDATE_EXCHANGE_TRANSFER_OUT_WALLET = 'START_UPDATE_EXCHANGE_TRANSFER_OUT_WALLET';
export const UPDATE_EXCHANGE_TRANSFER_OUT_WALLET_SUCCESS = 'UPDATE_EXCHANGE_TRANSFER_OUT_WALLET_SUCCESS';
export const UPDATE_EXCHANGE_TRANSFER_OUT_WALLET_FAILED = 'UPDATE_EXCHANGE_TRANSFER_OUT_WALLET_FAILED';

// Transfer Out Wallet Log
export const START_FETCH_TRANSFER_OUT_WALLET_LOGS = 'START_FETCH_TRANSFER_OUT_WALLET_LOGS';
export const FETCH_TRANSFER_OUT_WALLET_LOGS_SUCCESS = 'FETCH_TRANSFER_OUT_WALLET_LOGS_SUCCESS';
export const FETCH_TRANSFER_OUT_WALLET_LOGS_FAILED = 'FETCH_TRANSFER_OUT_WALLET_LOGS_FAILED';

// Exchange-setting
export const START_FETCH_EXCHANGE_SETTING = 'START_FETCH_EXCHANGE_SETTING';
export const FETCH_EXCHANGE_SETTING_SUCCESS = 'FETCH_EXCHANGE_SETTING_SUCCESS';
export const FETCH_EXCHANGE_SETTING_FAILED = 'FETCH_EXCHANGE_SETTING_FAILED';

export const START_UPDATE_EXCHANGE_SETTING = 'START_UPDATE_EXCHANGE_SETTING';
export const UPDATE_EXCHANGE_SETTING_SUCCESS = 'UPDATE_EXCHANGE_SETTING_SUCCESS';
export const UPDATE_EXCHANGE_SETTING_FAILED = 'UPDATE_EXCHANGE_SETTING_FAILED';

// Marquee
export const START_FETCH_MARQUEES = 'START_FETCH_MARQUEES';
export const FETCH_MARQUEES_SUCCESS = 'FETCH_MARQUEES_SUCCESS';
export const FETCH_MARQUEES_FAILED = 'FETCH_MARQUEES_FAILED';

export const START_CREATE_MARQUEE = 'START_CREATE_MARQUEE';
export const CREATE_MARQUEE_SUCCESS = 'CREATE_MARQUEE_SUCCESS';
export const CREATE_MARQUEE_FAILED = 'CREATE_MARQUEE_FAILED';

export const START_UPDATE_MARQUEE = 'START_UPDATE_MARQUEE';
export const UPDATE_MARQUEE_SUCCESS = 'UPDATE_MARQUEE_SUCCESS';
export const UPDATE_MARQUEE_FAILED = 'UPDATE_MARQUEE_FAILED';

export const START_DELETE_MARQUEE = 'START_DELETE_MARQUEE';
export const DELETE_MARQUEE_SUCCESS = 'DELETE_MARQUEE_SUCCESS';
export const DELETE_MARQUEE_FAILED = 'DELETE_MARQUEE_FAILED';

// CS Settings
export const START_FETCH_CS_SETTING = 'START_FETCH_CS_SETTING';
export const FETCH_CS_SETTING_SUCCESS = 'FETCH_CS_SETTING_SUCCESS';
export const FETCH_CS_SETTING_FAILED = 'FETCH_CS_SETTING_FAILED';

export const START_UPDATE_CS_SETTING = 'START_UPDATE_CS_SETTING';
export const UPDATE_CS_SETTING_SUCCESS = 'UPDATE_CS_SETTING_SUCCESS';
export const UPDATE_CS_SETTING_FAILED = 'UPDATE_CS_SETTING_FAILED';

import { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { SectionIdEnums, DevicesNameEnum } from 'client-console/lib/enums';
import { scrollToSection } from 'client-console/lib/scroll-to-utils';
import { handleTrackEvent } from 'client-console/lib/analytics-utils';
import { getNextWindowSize } from 'common-lib/window-size-utils';
import {
	PREFIX_CLASS,
	LINK_CLASS,
} from './utils';
import './style.styl';

const { ENERGY } = SectionIdEnums;

const propTypes = {
	className: PropTypes.string,
	navItems: PropTypes.array,
	onClickNavItem: PropTypes.func,
};

const defaultProps = {
	navItems: [],
	onClickNavItem: () => {},
};

const NavList = ({
	className,
	navItems,
	onClickNavItem,
}) => {
	const [activeLink, setActiveLink] = useState(ENERGY);
	const windowSize = getNextWindowSize();

	const _handleClick = ({ id, name, routeKey }) => {
		handleTrackEvent('click', 'menu', DevicesNameEnum[windowSize], name);

		onClickNavItem({ id, name, routeKey });
		scrollToSection(id);
	};

	const _checkActiveSection = useCallback(() => {
		const activeSection = navItems.find(({ id }) => {
			const section = document.getElementById(id);

			if (section) {
				const rect = section.getBoundingClientRect();

				return rect.top <= 120 && rect.bottom >= 120;
			}

			return false;
		});

		if (activeSection) {
			setActiveLink(activeSection.sectionId);
		}
	}, [navItems]);

	useEffect(() => {
		window.addEventListener('scroll', _checkActiveSection, { passive: true });

		return () => {
			window.removeEventListener('scroll', _checkActiveSection);
		};
	}, [_checkActiveSection]);

	return (
		<nav className={cx(PREFIX_CLASS, className)}>
			<ul>
				{
					navItems.map(({ id, name, routeKey }) => (
						<li
							key={id}
							className={cx(activeLink === id && 'active')}
							onClick={() => _handleClick({ id, name, routeKey })}
						>
							{
								routeKey ? (
									<Link className={LINK_CLASS} to={routeKey}>{name}</Link>
								) : (
									<span className={LINK_CLASS}>{name}</span>
								)
							}
						</li>
					))
				}
			</ul>
		</nav>
	);
};

NavList.propTypes = propTypes;
NavList.defaultProps = defaultProps;

export default NavList;

export const LoadingStatusEnum = {
	NONE: 0,
	SUCCESS: 1,
	FAILED: 2,
	LOADING: 3,
};

export const LoadingStatusList = Object.values(LoadingStatusEnum);

export const ModalStepEnum = {
	NONE: 0,
	STEP_ONE: 1,
	STEP_TWO: 2,
	STEP_THREE: 3,
};

export const ModalTypeEnum = {
	NONE: 0,
	CREATE: 1,
	MODIFY: 2,
};

export const ErrorTypeEnum = {
	TOTP_ERROR: 0,
};

export const TableOrderEnums = {
	ascend: 'asc',
	descend: 'desc',
};

export const AjaxRequestCodeEnums = {
	CONFLICT_ERROR: '888.022.409',
};

export const LanguageCodeEnums = {
	ZH_CN: 'zh-cn',
	EN: 'en',
};

export const WindowSizeEnum = {
	NONE: 0,
	MOBILE: 1,
	TABLET: 2,
	DESKTOP: 3,
	LG_DESKTOP: 4,
};

import { combineReducers } from 'redux';

import language from './language';
import marquee from './marquee';
import exchangeSetting from './exchange-setting';
import platformWallet from './platform-wallet';
import exchangeRate from './exchange-rate';
import latestReceiveLogs from './latest-receive-logs';
import csSetting from './cs-setting';
import contact from './contact';
import webHeadSource from './web-head-source';

const reducer = combineReducers({
	language,
	marquee,
	exchangeSetting,
	platformWallet,
	exchangeRate,
	latestReceiveLogs,
	csSetting,
	contact,
	webHeadSource,
});

const appReducer = (state, action) => reducer(state, action);

export default appReducer;

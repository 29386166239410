import { ofType } from 'redux-observable';
import {
	switchMap,
	map,
	catchError,
} from 'rxjs/operators';
import { catchErrorForEpics } from 'common-lib/epic-utils';
import { rxjsApiFetcher } from 'client-console/lib/rxjs-api-utils';
import {
	actionTypes,
	exchangeRateActions,
	notifyActions,
} from 'client-console/controller';

const {
	START_FETCH_EXCHANGE_RATE,
} = actionTypes;

const {
	fetchExchangeRateSuccessAction,
	fetchExchangeRateFailedAction,
} = exchangeRateActions;
const { notifyErrorAction } = notifyActions;

// GET api/v1/exchange-rates
// https://app.gitbook.com/o/-LSmguN2bxAKDgeXkorc/s/ONMcUSpUdQyEDkSpslSO/client-api/888.005-exchange-rate
// eslint-disable-next-line import/prefer-default-export
export function fetchExchangeRateEpic(action$) {
	return action$.pipe(
		ofType(START_FETCH_EXCHANGE_RATE),
		switchMap(() => rxjsApiFetcher
			.get('exchange-rates')
			.pipe(
				map(payload => payload.response),
				map(data => fetchExchangeRateSuccessAction(data)),
				catchError(error => catchErrorForEpics(error, fetchExchangeRateFailedAction, notifyErrorAction)),
			),
		),
	);
}

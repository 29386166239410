import { loadComponent } from 'common-lib/react-utils';
import RouteKeyEnums from './route-enums';

const Main = loadComponent({ loader: () => import('client-console/pages/main') });
const NotFound = loadComponent({ loader: () => import('client-console/pages/not-found') });

const {
	MAIN,
	ENERGY_EXCHANGE,
	USDT_EXCHANGE,
	LATEST_RECORDS,
	ABOUT,
	FAQ,
	NOT_FOUND,
} = RouteKeyEnums;

const routes = [
	{
		path: NOT_FOUND,
		component: NotFound,
	},
	{
		path: MAIN,
		component: Main,
	},
	{
		path: ENERGY_EXCHANGE,
		component: Main,
	},
	{
		path: USDT_EXCHANGE,
		component: Main,
	},
	{
		path: LATEST_RECORDS,
		component: Main,
	},
	{
		path: ABOUT,
		component: Main,
	},
	{
		path: FAQ,
		component: Main,
	},
];

export default routes;

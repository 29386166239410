export function getLocalStorage(key) {
	try {
		return JSON.parse(localStorage.getItem(key));
	} catch (error) {
		console.log(error);
		return undefined;
	}
}

export function setLocalStorage(key, value) {
	localStorage.setItem(key, JSON.stringify(value));
}

export function removeLocalStorage(key) {
	localStorage.removeItem(key);
}

import { Map } from 'immutable';
import { LoadingStatusEnum } from 'common-lib/enums';
import { actionTypes } from 'client-console/controller';

const {
	START_FETCH_EXCHANGE_RATE,
	FETCH_EXCHANGE_RATE_SUCCESS,
	FETCH_EXCHANGE_RATE_FAILED,
} = actionTypes;

const {
	NONE,
	LOADING,
	SUCCESS,
	FAILED,
} = LoadingStatusEnum;

const initialState = Map({
	rateMap: Map(),

	loadingStatus: NONE,
	loadingStatusMessage: '',
});

export default function exchangeRate(state = initialState, action) {
	switch (action.type) {
		case START_FETCH_EXCHANGE_RATE:
			return state
				.set('loadingStatus', LOADING);

		case FETCH_EXCHANGE_RATE_SUCCESS:
			return state
				.set('rateMap', Map(action.data))
				.set('loadingStatus', SUCCESS);

		case FETCH_EXCHANGE_RATE_FAILED:
			return state
				.set('loadingStatus', FAILED)
				.set('loadingStatusMessage', action.errorMessage);

		default:
			return state;
	}
}

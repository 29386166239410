// TODO
// import { memo } from 'react';
import PropTypes from 'prop-types';
import {
	Redirect,
	withRouter,
} from 'react-router-dom';
// TODO
// import { connect } from 'ljit-store-connecter';
// import { LoadingStatusEnum } from 'common-lib/enums';
import RouteKeyEnums from 'client-console/routes/route-enums';
// TODO import { IconLoading } from 'client-console/components/icon';
import { checkIsOmitPath } from './utils';

const {
	NOT_FOUND,
} = RouteKeyEnums;

const propTypes = {
	location: PropTypes.shape({
		pathname: PropTypes.string.isRequired,
	}).isRequired,
	render: PropTypes.func.isRequired,
	// TODO loadingStatus: PropTypes.oneOf(LoadingStatusList).isRequired,
	// validateClientIpLoadingStatus: PropTypes.oneOf(LoadingStatusList).isRequired,
	// checkAuthAction: PropTypes.func.isRequired,
	// startValidateClientIpAction: PropTypes.func.isRequired,
};

const AuthRoute = ({
	location,
	render,
}) => {
	const isAuthed = true; // TODO
	// TODO const isValidateIpFailed = validateClientIpLoadingStatus === FAILED;
	// const isValidateIpChecking = validateClientIpLoadingStatus === NONE
	// 	|| validateClientIpLoadingStatus === LOADING;
	// const isAuthChecking = loadingStatus === NONE
	// 	|| loadingStatus === LOADING;

	// TODO useEffect(() => {
	// 	if (validateClientIpLoadingStatus === NONE) {
	// 		startValidateClientIpAction();
	// 	}

	// 	if (validateClientIpLoadingStatus === SUCCESS
	// 		&& !checkIsOmitPath(location.pathname)) {
	// 		checkAuthAction();
	// 	}
	// }, [validateClientIpLoadingStatus]);

	// if (isValidateIpChecking) {
	// 	return IconLoading;
	// }

	if (!isAuthed && location.pathname !== NOT_FOUND) {
		return (
			<Redirect
				to={{
					pathname: NOT_FOUND,
					state: {
						from: location.pathname,
					},
				}}
			/>
		);
	}

	if (checkIsOmitPath(location.pathname)) {
		return render({ isAuthed: false });
	}

	// TODO if (isAuthChecking) {
	// 	return IconLoading;
	// }

	return render({ isAuthed });
};

AuthRoute.propTypes = propTypes;

// TODO
// function mapStateToProps() {
// 	return {
// 		// TODO loadingStatus: state.auth.get('loadingStatus'),
// 		// validateClientIpLoadingStatus: state.systemError.get('validateClientIpLoadingStatus'),
// 	};
// }

// function mapDispatchToProps() {
// 	return {
// 		// TODO checkAuthAction: () => dispatch(checkAuthAction()),
// 		// startValidateClientIpAction: () => dispatch(startValidateClientIpAction()),
// 	};
// }

// export default connect(mapStateToProps, mapDispatchToProps)(withRouter(memo(AuthRoute, areEqual)));

// const areEqual = (prevProps, nextProps) => (
// 	prevProps.loadingStatus === nextProps.loadingStatus
// 	&& checkIsOmitPath(nextProps.location.pathname)
// );

export default withRouter(AuthRoute);

import { systemErrorActions } from 'management-console/controller';
import ErrorCode from '../../server/lib/error/code';

const { showReloginMessageAction } = systemErrorActions;

const {
	USER_IS_UPDATED,
	USER_IS_ARCHIVED,
	USER_PASSWORD_IS_UPDATED,
	GOOGLE_TOTP_IS_DISABLED,
	USER_IS_BLOCKED,
	USER_ROLE_IS_BLOCKED,
	IP_NOT_MATCH_ANY_ONE,
	IP_NOT_IN_USER_IP_WHITELIST,
	WITHOUT_LOGGED_IN,
} = ErrorCode;
const ReLoginErrors = [
	USER_IS_UPDATED,
	USER_IS_ARCHIVED,
	USER_PASSWORD_IS_UPDATED,
	GOOGLE_TOTP_IS_DISABLED,
	USER_IS_BLOCKED,
	USER_ROLE_IS_BLOCKED,
	IP_NOT_MATCH_ANY_ONE,
	IP_NOT_IN_USER_IP_WHITELIST,
	WITHOUT_LOGGED_IN,
];

export const reLoginChecker = error => {
	const errorCode = error?.response?.code;

	if (!errorCode) {
		return;
	}

	const reLoginError = ReLoginErrors.find(reLoginError => reLoginError.CODE === errorCode);

	if (reLoginError) {
		return showReloginMessageAction(reLoginError.MESSAGE);
	}
};

export const blockChecker = error => {
	const httpStatus = error?.status;

	if (!httpStatus || httpStatus === 404) {
		return window.location.reload();
	}
};

import {
	START_FETCH_CS_SETTING,
	FETCH_CS_SETTING_SUCCESS,
	FETCH_CS_SETTING_FAILED,
} from './action-types';

export function fetchCSSettingAction() {
	return {
		type: START_FETCH_CS_SETTING,
	};
}

export function fetchCSSettingSuccessAction(csConfig) {
	return {
		type: FETCH_CS_SETTING_SUCCESS,
		csConfig,
	};
}

export function fetchCSSettingFailedAction(error, errorMessage) {
	return {
		type: FETCH_CS_SETTING_FAILED,
		error,
		errorMessage,
	};
}

import { ofType } from 'redux-observable';
import {
	switchMap,
	map,
	catchError,
} from 'rxjs/operators';
import { catchErrorForEpics } from 'common-lib/epic-utils';
import { rxjsApiFetcher } from 'client-console/lib/rxjs-api-utils';
import {
	actionTypes,
	csSettingActions,
	notifyActions,
} from 'client-console/controller';

const {
	START_FETCH_CS_SETTING,
} = actionTypes;

const {
	fetchCSSettingSuccessAction,
	fetchCSSettingFailedAction,
} = csSettingActions;
const { notifyErrorAction } = notifyActions;

// GET api/v1/cs-settings
// https://app.gitbook.com/o/-LSmguN2bxAKDgeXkorc/s/ONMcUSpUdQyEDkSpslSO/client-api/888.026-cs-setting
// eslint-disable-next-line import/prefer-default-export
export function fetchCSSettingEpic(action$) {
	return action$.pipe(
		ofType(START_FETCH_CS_SETTING),
		switchMap(() => rxjsApiFetcher
			.get('cs-settings')
			.pipe(
				map(payload => payload.response),
				map((csConfig = {}) => fetchCSSettingSuccessAction(csConfig)),
				catchError(error => catchErrorForEpics(error, fetchCSSettingFailedAction, notifyErrorAction)),
			),
		),
	);
}

import { Map } from 'immutable';
import { actionTypes } from 'client-console/controller';

const {
	SHOW_CONTACT_MODAL,
	HIDE_CONTACT_MODAL,
} = actionTypes;
const initialState = Map({
	isVisible: false,
});

export default function contact(state = initialState, action) {
	switch (action.type) {
		case SHOW_CONTACT_MODAL:
			return state.set('isVisible', true);

		case HIDE_CONTACT_MODAL:
			return state.set('isVisible', false);

		default:
			return state;
	}
}

import {
	START_FETCH_PLATFORM_WALLETS,
	FETCH_PLATFORM_WALLETS_SUCCESS,
	FETCH_PLATFORM_WALLETS_FAILED,

	START_FETCH_TRANSFER_IN_WALLET,
	FETCH_TRANSFER_IN_WALLET_SUCCESS,
	FETCH_TRANSFER_IN_WALLET_FAILED,
} from './action-types';

export function fetchPlatformWalletsAction() {
	return {
		type: START_FETCH_PLATFORM_WALLETS,
	};
}

export function fetchPlatformWalletsSuccessAction({ wallets }) {
	return {
		type: FETCH_PLATFORM_WALLETS_SUCCESS,
		wallets,
	};
}

export function fetchPlatformWalletsFailedAction(error, errorMessage) {
	return {
		type: FETCH_PLATFORM_WALLETS_FAILED,
		error,
		errorMessage,
	};
}

export function fetchTransferInWalletAction() {
	return {
		type: START_FETCH_TRANSFER_IN_WALLET,
	};
}

export function fetchTransferInWalletSuccessAction({ wallet }) {
	return {
		type: FETCH_TRANSFER_IN_WALLET_SUCCESS,
		wallet,
	};
}

export function fetchTransferInWalletFailedAction(error, errorMessage) {
	return {
		type: FETCH_TRANSFER_IN_WALLET_FAILED,
		error,
		errorMessage,
	};
}

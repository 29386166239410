import * as actionTypes from './actions/action-types';

import * as notifyActions from './actions/notify-actions';

import * as languageActions from './actions/language-action';
import * as marqueeActions from './actions/marquee-actions';
import * as exchangeSettingActions from './actions/exchange-setting-actions';
import * as exchangeRateActions from './actions/exchange-rate-actions';
import * as platformWalletActions from './actions/platform-wallet-actions';
import * as latestReceiveLogsActions from './actions/latest-receive-logs-actions';
import * as csSettingActions from './actions/cs-setting-actions';
import * as contactActions from './actions/contact-actions';
import * as webHeadSourceActions from './actions/web-head-source-actions';

export {
	actionTypes,

	notifyActions,
	languageActions,
	marqueeActions,
	exchangeSettingActions,
	exchangeRateActions,
	platformWalletActions,
	latestReceiveLogsActions,
	csSettingActions,
	contactActions,
	webHeadSourceActions,
};

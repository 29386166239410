import {
	fetchers,
	generateApiFetcher,
} from '../../lib/api-fetcher';

export function getAPIBaseUrl() {
	const { CLIENT_BASE_API_URL } = process.env;

	return CLIENT_BASE_API_URL;
}

const {
	rxAjaxFetcher,
} = fetchers;

export const rxjsApiFetcher = generateApiFetcher({
	fetcher: rxAjaxFetcher,
	baseUrl: getAPIBaseUrl(),
});

import { ofType } from 'redux-observable';
import {
	switchMap,
	map,
	catchError,
} from 'rxjs/operators';
import { catchErrorForEpics } from 'common-lib/epic-utils';
import { rxjsApiFetcher } from 'client-console/lib/rxjs-api-utils';
import {
	actionTypes,
	latestReceiveLogsActions,
	notifyActions,
} from 'client-console/controller';

const {
	START_FETCH_LATEST_RECEIVE_LOGS,
} = actionTypes;
const {
	fetchLatestReceiveLogsSuccessAction,
	fetchLatestReceiveLogsFailedAction,
} = latestReceiveLogsActions;
const {
	notifyErrorAction,
} = notifyActions;

// GET api/v1/latest-receive-logs 取得最新接收記錄
// https://app.gitbook.com/o/-LSmguN2bxAKDgeXkorc/s/ONMcUSpUdQyEDkSpslSO/client-api/888.006-latest-receive-log#qu-de-zui-xin-jie-shou-ji-lu
// eslint-disable-next-line import/prefer-default-export
export function fetchLatestReceiveLogsEpic(action$) {
	return action$.pipe(
		ofType(START_FETCH_LATEST_RECEIVE_LOGS),
		switchMap(() => rxjsApiFetcher
			.get('latest-receive-logs')
			.pipe(
				map(payload => payload.response),
				map(data => fetchLatestReceiveLogsSuccessAction(data)),
				catchError(error => catchErrorForEpics(error, fetchLatestReceiveLogsFailedAction, notifyErrorAction)),
			),
		),
	);
}

import { Map } from 'immutable';
import { actionTypes } from 'client-console/controller';

const {
	SET_LANGUAGE,
} = actionTypes;
const initialState = Map({
	data: '',
});

export default function language(state = initialState, action) {
	switch (action.type) {
		case SET_LANGUAGE: {
			return state.set('data', action.language);
		}

		default:
			return state;
	}
}

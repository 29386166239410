import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'ljit-store-connecter';
import { LanguageCodeEnums } from 'common-lib/enums';
import { usePrevious } from 'common-lib/react-utils';
import { getLocalStorage, setLocalStorage } from 'common-lib/local-storage-utils';
import {
	DEFAULT_LOCALE,
	i18nLanguageCodeMap,
	initI18n,
	changeLanguage,
} from 'common-lib/i18n';
import { languageActions } from 'client-console/controller';

const { setLanguage } = languageActions;

const { ZH_CN } = LanguageCodeEnums;

(() => {
	const searchParams = new URLSearchParams(window.location.search);

	const focusedLanguageCode = i18nLanguageCodeMap[searchParams.get('hl')] || DEFAULT_LOCALE;

	if (focusedLanguageCode) {
		setLocalStorage('locale', focusedLanguageCode);
	}
})();

const locale = getLocalStorage('locale')
	|| navigator.language
	|| navigator.userLanguage
	|| ZH_CN;

initI18n(locale);

const propTypes = {
	location: PropTypes.object,
	history: PropTypes.object,
	language: PropTypes.string,
	render: PropTypes.func.isRequired,

	setLanguage: PropTypes.func,
};
const defaultProps = {
	render: () => {},
};

const I18nRoute = ({
	location,
	history,
	language,
	render,

	setLanguage,
}) => {
	const prevLanguage = usePrevious(language);

	useEffect(() => {
		setLanguage(i18nLanguageCodeMap[locale]);
	}, [setLanguage]);

	useEffect(() => {
		if (language && prevLanguage && language !== prevLanguage) {
			const searchParams = new URLSearchParams(location.search);

			searchParams.set('hl', encodeURIComponent(language));
			history.replace({
				search: searchParams.toString(),
			});

			changeLanguage(language);
		}
	}, [prevLanguage, language, location, history]);

	return render();
};

function mapStateToProps(state) {
	return {
		language: state.language.get('data'),
	};
}

function mapDispatchToProps(dispatch) {
	return {
		setLanguage: language => dispatch(setLanguage(language)),
	};
}

I18nRoute.propTypes = propTypes;
I18nRoute.defaultProps = defaultProps;

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(I18nRoute));

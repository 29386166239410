import { Notify, Modal } from 'ibears-react-components';

const { Success: success } = Modal;

const NotifyTypeEnum = {
	INFO: 'info',
	ERROR: 'error',
	SUCCESS: 'success',
	SUCCESS_FILL: 'successFill',
};

const createNotification = (type, message, delay) => Notify[type](message, delay);

const reLoginNotify = ({ text, nextStep = () => {} }) => {
	success({
		title: '成功提示',
		content: `${text}成功，请重新登入`,
		okText: '确 定',
		centered: true,
		onOk: () => nextStep(),
	});
};

const successNotify = ({
	title = '成功提示',
	content = '成功',
	okText = '确 定',
	nextStep = () => {},
}) => {
	success({
		title,
		content,
		okText,
		centered: true,
		onOk: nextStep,
	});
};

export {
	NotifyTypeEnum,
	createNotification,
	reLoginNotify,
	successNotify,
};

import {
	START_FETCH_EXCHANGE_RATE,
	FETCH_EXCHANGE_RATE_SUCCESS,
	FETCH_EXCHANGE_RATE_FAILED,
} from './action-types';

export function fetchExchangeRateAction() {
	return {
		type: START_FETCH_EXCHANGE_RATE,
	};
}

export function fetchExchangeRateSuccessAction(data) {
	return {
		type: FETCH_EXCHANGE_RATE_SUCCESS,
		data,
	};
}

export function fetchExchangeRateFailedAction(error, errorMessage) {
	return {
		type: FETCH_EXCHANGE_RATE_FAILED,
		error,
		errorMessage,
	};
}

import { useState, useEffect, useRef } from 'react';
import { WindowSizeEnum } from './enums';

const {
	NONE,
	MOBILE,
	TABLET,
	DESKTOP,
	LG_DESKTOP,
} = WindowSizeEnum;
let globalWindowSize = NONE;
const SM_WIDTH = 767;
const MD_WIDTH = 1024;
const LG_WIDTH = 1366;
const refObservers = [];

const getNextWindowSize = () => {
	const { innerWidth } = window;
	let nextWindowSize = MOBILE;

	if (innerWidth >= LG_WIDTH) {
		nextWindowSize = LG_DESKTOP;
	} else if (innerWidth > MD_WIDTH) {
		nextWindowSize = DESKTOP;
	} else if (innerWidth > SM_WIDTH) {
		nextWindowSize = TABLET;
	}

	return nextWindowSize;
};

const handleWindowResize = () => {
	const nextWindowSize = getNextWindowSize();

	if (nextWindowSize !== globalWindowSize) {
		globalWindowSize = nextWindowSize;

		if (refObservers.length) {
			refObservers.forEach(observer => observer.current(globalWindowSize));
		}
	}
};

window.addEventListener('resize', handleWindowResize);
handleWindowResize();

const subscribeWindowResize = refObserver => {
	refObservers.push(refObserver);
};

const unsubscribeWindowResize = refObserver => {
	const index = refObservers.indexOf(refObserver);

	if (index > -1) {
		refObservers.splice(index, 1);
	}
};

const useWindowSize = () => {
	const [windowSize, setWindowSize] = useState(globalWindowSize || getNextWindowSize());
	const refWindowResizeObserver = useRef();

	useEffect(() => {
		refWindowResizeObserver.current = nextWindowSize => {
			setWindowSize(nextWindowSize);
		};

		subscribeWindowResize(refWindowResizeObserver);

		return () => {
			unsubscribeWindowResize(refWindowResizeObserver);
		};
	}, []);

	return windowSize;
};

export {
	getNextWindowSize,
	useWindowSize,
};

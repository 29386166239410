import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import TERTopic from 'client-console/components/ter-topic';
import RouteKeyEnums from 'client-console/routes/route-enums';
import { scrollToTop } from 'client-console/lib/scroll-to-utils';
import './style.styl';

const { MAIN } = RouteKeyEnums;

const PREFIX_CLASS = 'logo';

const propTypes = {
	onClick: PropTypes.func,
};
const defaultProps = {
	onClick: () => {},
};

const Logo = ({
	onClick,
}) => (
	<Link
		className={PREFIX_CLASS}
		to={MAIN}
		onClick={() => {
			scrollToTop();
			onClick();
		}}
	>
		<TERTopic/>
	</Link>
);

Logo.propTypes = propTypes;
Logo.defaultProps = defaultProps;

export default Logo;

import {
	START_FETCH_EXCHANGE_SETTING,
	FETCH_EXCHANGE_SETTING_SUCCESS,
	FETCH_EXCHANGE_SETTING_FAILED,
} from './action-types';

export function fetchExchangeSettingAction() {
	return {
		type: START_FETCH_EXCHANGE_SETTING,
	};
}

export function fetchExchangeSettingSuccessAction(config) {
	return {
		type: FETCH_EXCHANGE_SETTING_SUCCESS,
		config,
	};
}

export function fetchExchangeSettingFailedAction(error, errorMessage) {
	return {
		type: FETCH_EXCHANGE_SETTING_FAILED,
		error,
		errorMessage,
	};
}

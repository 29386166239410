import { DetailsTypeEnum } from 'management-console/lib/enums';
import {
	START_FETCH_BASIC_EXCHANGE_TRANSFER_OUT_WALLETS,
	FETCH_BASIC_EXCHANGE_TRANSFER_OUT_WALLETS_SUCCESS,
	FETCH_BASIC_EXCHANGE_TRANSFER_OUT_WALLETS_FAILED,

	START_FETCH_EXCHANGE_TRANSFER_OUT_WALLETS,
	FETCH_EXCHANGE_TRANSFER_OUT_WALLETS_SUCCESS,
	FETCH_EXCHANGE_TRANSFER_OUT_WALLETS_FAILED,

	START_CREATE_EXCHANGE_TRANSFER_OUT_WALLET,
	CREATE_EXCHANGE_TRANSFER_OUT_WALLET_SUCCESS,
	CREATE_EXCHANGE_TRANSFER_OUT_WALLET_FAILED,

	START_UPDATE_EXCHANGE_TRANSFER_OUT_WALLET,
	UPDATE_EXCHANGE_TRANSFER_OUT_WALLET_SUCCESS,
	UPDATE_EXCHANGE_TRANSFER_OUT_WALLET_FAILED,
} from './action-types';

const { BASIC, FULL } = DetailsTypeEnum;

export function fetchBasicExchangeTransferOutWalletsAction(queries = {}) {
	return {
		type: START_FETCH_BASIC_EXCHANGE_TRANSFER_OUT_WALLETS,
		queries: { ...queries, details: BASIC },
	};
}

export function fetchBasicExchangeTransferOutWalletsSuccessAction({
	basicWallets,
}) {
	return {
		type: FETCH_BASIC_EXCHANGE_TRANSFER_OUT_WALLETS_SUCCESS,
		basicWallets,
	};
}

export function fetchBasicExchangeTransferOutWalletsFailedAction(error, errorMessage) {
	return {
		type: FETCH_BASIC_EXCHANGE_TRANSFER_OUT_WALLETS_FAILED,
		error,
		errorMessage,
	};
}

export function fetchExchangeTransferOutWalletsAction(queries = {}) {
	return {
		type: START_FETCH_EXCHANGE_TRANSFER_OUT_WALLETS,
		queries: { ...queries, details: FULL },
	};
}

export function fetchExchangeTransferOutWalletsSuccessAction({
	queries,
	wallets,
	numOfItems,
}) {
	return {
		type: FETCH_EXCHANGE_TRANSFER_OUT_WALLETS_SUCCESS,
		queries,
		wallets,
		numOfItems,
	};
}

export function fetchExchangeTransferOutWalletsFailedAction(error, errorMessage) {
	return {
		type: FETCH_EXCHANGE_TRANSFER_OUT_WALLETS_FAILED,
		error,
		errorMessage,
	};
}

export function createExchangeTransferOutWalletAction(data) {
	return {
		type: START_CREATE_EXCHANGE_TRANSFER_OUT_WALLET,
		data,
	};
}

export function createExchangeTransferOutWalletSuccessAction() {
	return {
		type: CREATE_EXCHANGE_TRANSFER_OUT_WALLET_SUCCESS,
	};
}

export function createExchangeTransferOutWalletFailedAction(error, errorMessage) {
	return {
		type: CREATE_EXCHANGE_TRANSFER_OUT_WALLET_FAILED,
		error,
		errorMessage,
	};
}

export function updateExchangeTransferOutWalletAction(walletId, data) {
	return {
		type: START_UPDATE_EXCHANGE_TRANSFER_OUT_WALLET,
		walletId,
		data,
	};
}

export function updateExchangeTransferOutWalletSuccessAction() {
	return {
		type: UPDATE_EXCHANGE_TRANSFER_OUT_WALLET_SUCCESS,
	};
}

export function updateExchangeTransferOutWalletFailedAction(error, errorMessage) {
	return {
		type: UPDATE_EXCHANGE_TRANSFER_OUT_WALLET_FAILED,
		error,
		errorMessage,
	};
}

import { WindowSizeEnum } from 'common-lib/enums';
import { getNextWindowSize } from 'common-lib/window-size-utils';

const PREFIX_CLASS = 'layout-header';
const TITLE_CLASS = `${PREFIX_CLASS}__title`;
const CONTENT_CLASS = `${PREFIX_CLASS}__content`;
const FOOTER_CLASS = `${PREFIX_CLASS}__footer`;

const {
	MOBILE,
} = WindowSizeEnum;

const getHeaderHeight = () => {
	const windowSize = getNextWindowSize();

	switch (windowSize) {
		case MOBILE:
			return 50;

		default:
			return 70;
	}
};

export {
	PREFIX_CLASS,
	TITLE_CLASS,
	CONTENT_CLASS,
	FOOTER_CLASS,

	getHeaderHeight,
};

import {
	SHOW_CONTACT_MODAL,
	HIDE_CONTACT_MODAL,
} from './action-types';

export function showContactModal() {
	return {
		type: SHOW_CONTACT_MODAL,
	};
}

export function hideContactModal() {
	return {
		type: HIDE_CONTACT_MODAL,
	};
}

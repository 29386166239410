import {
	createStore as reduxCreateStore,
	applyMiddleware,
} from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { Provider } from 'react-redux';
import {
	epics,
	reducer,
	middlewares as reduxMiddlewares,
} from './redux';

const {
	epicMiddleware,
	loggerMiddleWare,
	notifyHandlingMiddleware,
	languageMiddleware,
} = reduxMiddlewares;

export function createStore(initState = {}) {
	const applyMiddlewareList = [languageMiddleware];
	let store;

	if (process.env.IS_DEV) {
		applyMiddlewareList.push(loggerMiddleWare, epicMiddleware, notifyHandlingMiddleware);

		store = reduxCreateStore(reducer, initState, composeWithDevTools(applyMiddleware(...applyMiddlewareList)));
	} else {
		applyMiddlewareList.push(epicMiddleware, notifyHandlingMiddleware);

		store = reduxCreateStore(reducer, initState, applyMiddleware(...applyMiddlewareList));
	}

	epicMiddleware.run(epics);

	return store;
}

export const StoreProvider = Provider;

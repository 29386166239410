const paramRule = /:(\w+)/g;
const paramReplace = (text = '', params = {}) => text.replace(paramRule, (_, key) => (params[key] || key));
const getRouteMetaConfig = metaConfig => ({
	...metaConfig,
	isCrumbActive: Boolean(metaConfig),
	isCrumbVisible: metaConfig?.isCrumbVisible,
	isBannerVisible: Boolean(metaConfig),
});

export {
	paramReplace,
	getRouteMetaConfig,
};

import {
	SET_LANGUAGE,
} from './action-types';

// eslint-disable-next-line import/prefer-default-export
export function setLanguage(language) {
	return {
		type: SET_LANGUAGE,
		language,
	};
}


import { Map, List } from 'immutable';
import { LoadingStatusEnum } from 'common-lib/enums';
import { actionTypes } from 'client-console/controller';

const {
	START_FETCH_PLATFORM_WALLETS,
	FETCH_PLATFORM_WALLETS_SUCCESS,
	FETCH_PLATFORM_WALLETS_FAILED,

	START_FETCH_TRANSFER_IN_WALLET,
	FETCH_TRANSFER_IN_WALLET_SUCCESS,
	FETCH_TRANSFER_IN_WALLET_FAILED,
} = actionTypes;

const {
	NONE,
	LOADING,
	SUCCESS,
	FAILED,
} = LoadingStatusEnum;

const initialState = Map({
	platformWalletList: List(),

	loadingStatus: NONE,
	loadingStatusMessage: '',

	transferInWalletMap: Map(),

	transferLoadingStatus: NONE,
	transferLoadingStatusMessage: '',
});

export default function platformWallet(state = initialState, action) {
	switch (action.type) {
		case START_FETCH_PLATFORM_WALLETS:
			return state.set('loadingStatus', LOADING);

		case FETCH_PLATFORM_WALLETS_SUCCESS: {
			const { wallets } = action;

			return state
				.set('platformWalletList', List(wallets))
				.set('loadingStatus', SUCCESS);
		}

		case FETCH_PLATFORM_WALLETS_FAILED:
			return state
				.set('loadingStatus', FAILED)
				.set('loadingStatusMessage', action.errorMessage);

		case START_FETCH_TRANSFER_IN_WALLET:
			return state.set('transferLoadingStatus', LOADING);

		case FETCH_TRANSFER_IN_WALLET_SUCCESS: {
			const { wallet } = action;

			return state
				.set('transferInWalletMap', Map(wallet))
				.set('transferLoadingStatus', SUCCESS);
		}

		case FETCH_TRANSFER_IN_WALLET_FAILED:
			return state
				.set('transferLoadingStatus', FAILED)
				.set('transferLoadingStatusMessage', action.errorMessage);

		default:
			return state;
	}
}

import { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'ljit-store-connecter';
import { WindowSizeEnum } from 'common-lib/enums';
import { useWindowSize } from 'common-lib/window-size-utils';
import Hamburger from 'client-console/features/hamburger';
import NavList from 'client-console/components/nav-list';
import LocaleButton from 'client-console/features/locale-button';
import Logo from 'client-console/components/logo';
import { contactActions } from 'client-console/controller';
import {
	FixedFunctionEnum,
	getNavItems,
	getTranslateNavItems,
} from 'client-console/lib/nav-utils';
import {
	PREFIX_CLASS,
	TITLE_CLASS,
	CONTENT_CLASS,
	FOOTER_CLASS,

} from './utils';
import './style.styl';

const { showContactModal } = contactActions;
const { MOBILE } = WindowSizeEnum;
const { CONTACT: CONTACT_FUNC } = FixedFunctionEnum;

const propTypes = {
	showContactModal: PropTypes.func,
	routeKey: PropTypes.string,
};

const Header = ({
	showContactModal,
	routeKey,
}) => {
	const { t } = useTranslation();
	const [isOpen, setIsOpen] = useState(false);
	const windowWidth = useWindowSize();
	const navItems = getNavItems(routeKey);
	const translateNavItems = getTranslateNavItems(navItems, t);

	const _handleToggle = () => setIsOpen(prevIsOpen => !prevIsOpen);

	const _handleClickNavItem = ({ id: navId }) => {
		switch (navId) {
			case CONTACT_FUNC:
				showContactModal();
				break;

			default:
				break;
		}
	};

	const _renderRWDBody = () => {
		if (windowWidth === MOBILE) {
			return (
				<div className={FOOTER_CLASS}>
					<Hamburger
						navItems={translateNavItems}
						isOpen={isOpen}
						onToggle={_handleToggle}
						onClickNavItem={_handleClickNavItem}
					/>
				</div>
			);
		}

		return (
			<>
				<div className={CONTENT_CLASS}>
					<NavList
						navItems={translateNavItems}
						onClickNavItem={_handleClickNavItem}
					/>
				</div>
				<div className={FOOTER_CLASS}>
					<LocaleButton/>
				</div>
			</>
		);
	};

	return (
		<header className={PREFIX_CLASS}>
			<div className={TITLE_CLASS}><Logo/></div>
			{_renderRWDBody()}
		</header>
	);
};

function mapStateToProps() {
	return {};
}

function mapDispatchToProps(dispatch) {
	return {
		showContactModal: () => dispatch(showContactModal()),
	};
}

Header.propTypes = propTypes;

export default connect(mapStateToProps, mapDispatchToProps)(Header);

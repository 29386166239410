import { SectionIdEnums } from 'client-console/lib/enums';
import RouteKeyEnums from 'client-console/routes/route-enums';

const {
	MAIN,
	ENERGY_EXCHANGE,
	USDT_EXCHANGE,
	LATEST_RECORDS,
	ABOUT,
	FAQ,
} = RouteKeyEnums;
const {
	ENERGY,
	EXCHANGE,
	RECORD,
	PLATFORM,
	QUESTIONS,
} = SectionIdEnums;

const NavGroupEnum = {
	DEFAULT: 'DEFAULT',
};
const {
	DEFAULT,
} = NavGroupEnum;

const FixedFunctionEnum = {
	CONTACT: 'contact',
};
const {
	CONTACT,
} = FixedFunctionEnum;

const RouteKeyNavGroupMap = {
	[MAIN]: DEFAULT,
	[ENERGY_EXCHANGE]: DEFAULT,
	[USDT_EXCHANGE]: DEFAULT,
	[LATEST_RECORDS]: DEFAULT,
	[ABOUT]: DEFAULT,
	[FAQ]: DEFAULT,
};

const NavGroupSectionsMap = {
	[DEFAULT]: [
		{
			id: ENERGY,
			name: '能量兑换',
			routeKey: ENERGY_EXCHANGE,
		},
		{
			id: EXCHANGE,
			name: '数字币兑换',
			routeKey: USDT_EXCHANGE,
		},
		{
			id: RECORD,
			name: '最新接收记录',
			routeKey: LATEST_RECORDS,
		},
		{
			id: PLATFORM,
			name: '平台介绍',
			routeKey: ABOUT,
		},
		{
			id: QUESTIONS,
			name: '常见问题',
			routeKey: FAQ,
		},
	],
};

const NavGroupFixedFunctionMap = {
	[DEFAULT]: [
		{
			id: CONTACT,
			name: '在线客服',
		},
	],
};

const getNavItems = routeKey => {
	const NavGroup = RouteKeyNavGroupMap[routeKey] || DEFAULT;
	const Sections = NavGroupSectionsMap[NavGroup];
	const FixFunctions = NavGroupFixedFunctionMap[NavGroup];

	return [
		...Sections,
		...FixFunctions,
	];
};

const getTranslateNavItems = (navItems, t) => navItems.map(navItem => ({
	...navItem,
	name: t(navItem.name),
}));

export {
	RouteKeyNavGroupMap,
	NavGroupSectionsMap,
	NavGroupFixedFunctionMap,
	FixedFunctionEnum,
	DEFAULT,

	getNavItems,
	getTranslateNavItems,
};

import { Map } from 'immutable';
import { LoadingStatusEnum } from 'common-lib/enums';
import { actionTypes } from 'client-console/controller';

const {
	START_FETCH_EXCHANGE_SETTING,
	FETCH_EXCHANGE_SETTING_SUCCESS,
	FETCH_EXCHANGE_SETTING_FAILED,
} = actionTypes;

const {
	NONE,
	LOADING,
	SUCCESS,
	FAILED,
} = LoadingStatusEnum;

const initialState = Map({
	exchangeConfigMap: Map(),

	loadingStatus: NONE,
	loadingStatusMessage: '',
});

export default function exchangeSetting(state = initialState, action) {
	switch (action.type) {
		case START_FETCH_EXCHANGE_SETTING:
			return state
				.set('loadingStatus', LOADING);

		case FETCH_EXCHANGE_SETTING_SUCCESS:
			return state
				.set('exchangeConfigMap', Map(action.config))
				.set('loadingStatus', SUCCESS);

		case FETCH_EXCHANGE_SETTING_FAILED:
			return state
				.set('loadingStatus', FAILED)
				.set('loadingStatusMessage', action.errorMessage);

		default:
			return state;
	}
}

import { Map } from 'immutable';
import { actionTypes } from 'client-console/controller';

const {
	INIT_WEB_HEAD_SOURCE,

	SET_WEB_DEFAULT_NICKNAME,

	SET_WEB_NICKNAME,
	RESET_WEB_NICKNAME,

	SET_WEB_DESCRIPTION,
	RESET_WEB_DESCRIPTION,

	SET_WEB_STRUCTURED_DATA,
	RESET_WEB_STRUCTURED_DATA,
} = actionTypes;

const initialState = Map({
	name: process.env.CLIENT_WEB_NAME,

	defaultNickname: process.env.CLIENT_WEB_DEFAULT_NICKNAME,
	nickname: '',

	defaultDescription: process.env.CLIENT_WEB_DEFAULT_DESCRIPTION,
	description: '',

	defaultStructuredData: '',
	structuredData: '',
});

export default function webHeadSource(state = initialState, action) {
	switch (action.type) {
		case INIT_WEB_HEAD_SOURCE: {
			const {
				name,
				defaultNickname,
				defaultDescription,
				defaultStructuredData,
			} = action;

			return state
				.set('name', name)
				.set('defaultNickname', defaultNickname)
				.set('nickname', '')
				.set('defaultDescription', defaultDescription)
				.set('description', '')
				.set('defaultStructuredData', defaultStructuredData)
				.set('structuredData', '');
		}

		case SET_WEB_DEFAULT_NICKNAME:
			return state.set('defaultNickname', action.defaultNickname);

		case SET_WEB_NICKNAME: {
			const { nickname } = action;

			return state.set('nickname', nickname);
		}

		case RESET_WEB_NICKNAME:
			return state.set('nickname', '');

		case SET_WEB_DESCRIPTION: {
			const { description } = action;

			return state.set('description', description);
		}

		case RESET_WEB_DESCRIPTION:
			return state.set('description', '');

		case SET_WEB_STRUCTURED_DATA: {
			const { structuredData } = action;

			return state.set('structuredData', structuredData);
		}

		case RESET_WEB_STRUCTURED_DATA:
			return state.set('structuredData', '');

		default:
			return state;
	}
}

const webName = process.env.CLIENT_WEB_NAME;
const defaultWebNickname = process.env.CLIENT_WEB_DEFAULT_NICKNAME;
const defaultWebDescription = process.env.CLIENT_WEB_DEFAULT_DESCRIPTION;

const getMetaTag = name => {
	let metaTag = document.querySelector(`meta[name="${name}"]`);

	if (!metaTag) {
		metaTag = document.createElement('meta');
		metaTag.setAttribute('name', name);
		document.head.appendChild(metaTag);
	}

	return metaTag;
};

const useDynamicTitle = ({
	name,
	defaultNickname,
	nickname,
}) => {
	if (nickname) {
		document.title = `${nickname} | ${name}`;
		return;
	}

	document.title = `${name}|${defaultNickname}`;
};

const useDynamicDescription = ({
	defaultDescription,
	description,
}) => {
	const metaTag = getMetaTag('description');

	if (description) {
		metaTag.setAttribute('content', description);
		return;
	}

	metaTag.setAttribute('content', defaultDescription);
};

const useDynamicStructuredData = ({
	defaultStructuredData,
	structuredData,
}) => {
	const jsonLDStr = structuredData || defaultStructuredData;
	let script = document.querySelector('script[type="application/ld+json"]');

	if (!script) {
		script = document.createElement('script');
		script.type = 'application/ld+json';
		document.head.appendChild(script);
	}

	if (jsonLDStr) {
		script.textContent = jsonLDStr;
	} else {
		script.remove();
	}
};

const useDynamicHeadSource = ({
	name,
	defaultNickname,
	nickname,
	defaultDescription,
	description,
	defaultStructuredData,
	structuredData,
}) => {
	useDynamicTitle({
		name,
		defaultNickname,
		nickname,
	});
	useDynamicDescription({
		defaultDescription,
		description,
	});
	useDynamicStructuredData({
		defaultStructuredData,
		structuredData,
	});
};

export {
	webName,
	defaultWebNickname,
	defaultWebDescription,
	useDynamicHeadSource,
};

import { useTranslation } from 'react-i18next';
import './style.styl';

const PREFIX_CLASS = 'layout-footer';

const Footer = () => {
	const { t } = useTranslation();

	return (
		<footer className={PREFIX_CLASS}>
			{`ter888.com ${t('波场能量租赁平台')}`}
		</footer>
	);
};

export default Footer;

/* eslint-disable react/static-property-placement */
/* eslint-disable react/state-in-constructor */
import { Component } from 'react';

let _store = {
	getState: () => {},
	dispatch: () => {},
	subscribe: () => {},
};
let _connect = defaultConnect;

function defaultMapToProps() {
	return {};
}

function defaultConnect(
	mapStateToProps = defaultMapToProps,
	mapDispatchToProps = defaultMapToProps,
) {
	return function (WrappedComponent) {
		class ConnectedComponent extends Component {
			constructor(props) {
				super(props);

				this._isMounted = true;
				this.state = {
					stateProps: mapStateToProps(_store.getState()),
				};
				this._handleUpdate = this._handleUpdate.bind(this);
				this._dispatchProps = mapDispatchToProps(_store.dispatch);
				this._unsubscribe = _store.subscribe(this._handleUpdate);
			}

			_handleUpdate() {
				if (this._isMounted) {
					this.setState({
						stateProps: mapStateToProps(_store.getState()),
					});
				}
			}

			render() {
				const { stateProps } = this.state;

				return (
					<WrappedComponent
						{...this.props}
						{...stateProps}
						{...this._dispatchProps}
					/>
				);
			}

			componentWillUnmount() {
				this._isMounted = false;

				if (typeof this._unsubscribe === 'function') {
					this._unsubscribe();
				}
			}
		}

		ConnectedComponent.displayName = `WithConnected(${getDisplayName(WrappedComponent)})`;

		return ConnectedComponent;
	};
}

function getDisplayName(WrappedComponent) {
	return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

export function initController(store = _store, connect = defaultConnect) {
	_store = store;
	_connect = connect;
}

export const connect = _connect;

import {
	INIT_WEB_HEAD_SOURCE,

	SET_WEB_DEFAULT_NICKNAME,

	SET_WEB_NICKNAME,
	RESET_WEB_NICKNAME,

	SET_WEB_DESCRIPTION,
	RESET_WEB_DESCRIPTION,

	SET_WEB_STRUCTURED_DATA,
	RESET_WEB_STRUCTURED_DATA,
} from './action-types';

export function initWebHeadSourceAction({
	name,
	defaultNickname,
	defaultDescription,
	defaultStructuredData = '',
}) {
	return {
		type: INIT_WEB_HEAD_SOURCE,
		name,
		defaultNickname,
		defaultDescription,
		defaultStructuredData,
	};
}

export function setWebDefaultNicknameAction(defaultNickname) {
	return {
		type: SET_WEB_DEFAULT_NICKNAME,
		defaultNickname,
	};
}

export function setWebNicknameAction(nickname) {
	return {
		type: SET_WEB_NICKNAME,
		nickname,
	};
}

export function resetNicknameAction() {
	return {
		type: RESET_WEB_NICKNAME,
	};
}

export function setWebDescriptionAction(description) {
	return {
		type: SET_WEB_DESCRIPTION,
		description,
	};
}

export function resetWebDescriptionAction() {
	return {
		type: RESET_WEB_DESCRIPTION,
	};
}

export function setWebStructuredDataAction(structuredData) {
	return {
		type: SET_WEB_STRUCTURED_DATA,
		structuredData,
	};
}

export function resetWebStructuredDataAction() {
	return {
		type: RESET_WEB_STRUCTURED_DATA,
	};
}

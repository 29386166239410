import { DetailsTypeEnum } from 'management-console/lib/enums';
import {
	START_FETCH_BASIC_PLEDGE_WALLETS,
	FETCH_BASIC_PLEDGE_WALLETS_SUCCESS,
	FETCH_BASIC_PLEDGE_WALLETS_FAILED,

	START_FETCH_PLEDGE_WALLETS,
	FETCH_PLEDGE_WALLETS_SUCCESS,
	FETCH_PLEDGE_WALLETS_FAILED,

	START_CREATE_PLEDGE_WALLET,
	CREATE_PLEDGE_WALLET_SUCCESS,
	CREATE_PLEDGE_WALLET_FAILED,

	START_UPDATE_PLEDGE_WALLET,
	UPDATE_PLEDGE_WALLET_SUCCESS,
	UPDATE_PLEDGE_WALLET_FAILED,

	START_FREEZE_PLEDGE_WALLET,
	FREEZE_PLEDGE_WALLET_SUCCESS,
	FREEZE_PLEDGE_WALLET_FAILED,

	START_UNFREZZE_PLEDGE_WALLET,
	UNFREZZE_PLEDGE_WALLET_SUCCESS,
	UNFREZZE_PLEDGE_WALLET_FAILED,

	START_WITHDRAW_PLEDGE_WALLET,
	WITHDRAW_PLEDGE_WALLET_SUCCESS,
	WITHDRAW_PLEDGE_WALLET_FAILED,

	START_RECYCLE_PLEDGE_WALLET,
	RECYCLE_PLEDGE_WALLET_SUCCESS,
	RECYCLE_PLEDGE_WALLET_FAILED,
} from './action-types';

const { BASIC, FULL } = DetailsTypeEnum;

export function fetchBasicPledgeWalletsAction(queries = {}) {
	return {
		type: START_FETCH_BASIC_PLEDGE_WALLETS,
		queries: { ...queries, details: BASIC },
	};
}

export function fetchBasicPledgeWalletsSuccessAction(basicWallets) {
	return {
		type: FETCH_BASIC_PLEDGE_WALLETS_SUCCESS,
		basicWallets,
	};
}

export function fetchBasicPledgeWalletsFailedAction(error, errorMessage) {
	return {
		type: FETCH_BASIC_PLEDGE_WALLETS_FAILED,
		error,
		errorMessage,
	};
}

export function fetchPledgeWalletsAction(queries = {}) {
	return {
		type: START_FETCH_PLEDGE_WALLETS,
		queries: { ...queries, details: FULL },
	};
}

export function fetchPledgeWalletsSuccessAction(queries, wallets, numOfItems) {
	return {
		type: FETCH_PLEDGE_WALLETS_SUCCESS,
		queries,
		wallets,
		numOfItems,
	};
}

export function fetchPledgeWalletsFailedAction(error, errorMessage) {
	return {
		type: FETCH_PLEDGE_WALLETS_FAILED,
		error,
		errorMessage,
	};
}

export function createPledgeWalletAction(data) {
	return {
		type: START_CREATE_PLEDGE_WALLET,
		data,
	};
}

export function createPledgeWalletSuccessAction() {
	return {
		type: CREATE_PLEDGE_WALLET_SUCCESS,
	};
}

export function createPledgeWalletFailedAction(error, errorMessage) {
	return {
		type: CREATE_PLEDGE_WALLET_FAILED,
		error,
		errorMessage,
	};
}

export function updatePledgeWalletAction(walletId, data) {
	return {
		type: START_UPDATE_PLEDGE_WALLET,
		walletId,
		data,
	};
}

export function updatePledgeWalletSuccessAction() {
	return {
		type: UPDATE_PLEDGE_WALLET_SUCCESS,
	};
}

export function updatePledgeWalletFailedAction(error, errorMessage) {
	return {
		type: UPDATE_PLEDGE_WALLET_FAILED,
		error,
		errorMessage,
	};
}

export function freezePledgeWalletAction(walletId, data) {
	return {
		type: START_FREEZE_PLEDGE_WALLET,
		walletId,
		data,
	};
}

export function freezePledgeWalletSuccessAction() {
	return {
		type: FREEZE_PLEDGE_WALLET_SUCCESS,
	};
}

export function freezePledgeWalletFailedAction(error, errorMessage) {
	return {
		type: FREEZE_PLEDGE_WALLET_FAILED,
		error,
		errorMessage,
	};
}

export function unfreezePledgeWalletAction(walletId, data) {
	return {
		type: START_UNFREZZE_PLEDGE_WALLET,
		walletId,
		data,
	};
}

export function unfreezePledgeWalletSuccessAction() {
	return {
		type: UNFREZZE_PLEDGE_WALLET_SUCCESS,
	};
}

export function unfreezePledgeWalletFailedAction(error, errorMessage) {
	return {
		type: UNFREZZE_PLEDGE_WALLET_FAILED,
		error,
		errorMessage,
	};
}

export function withdrawPledgeWalletAction(walletId, data) {
	return {
		type: START_WITHDRAW_PLEDGE_WALLET,
		walletId,
		data,
	};
}

export function withdrawPledgeWalletSuccessAction() {
	return {
		type: WITHDRAW_PLEDGE_WALLET_SUCCESS,
	};
}

export function withdrawPledgeWalletFailedAction(error, errorMessage) {
	return {
		type: WITHDRAW_PLEDGE_WALLET_FAILED,
		error,
		errorMessage,
	};
}

export function recyclePledgeWalletAction(walletId, data) {
	return {
		type: START_RECYCLE_PLEDGE_WALLET,
		walletId,
		data,
	};
}

export function recyclePledgeWalletSuccessAction() {
	return {
		type: RECYCLE_PLEDGE_WALLET_SUCCESS,
	};
}

export function recyclePledgeWalletFailedAction(error, errorMessage) {
	return {
		type: RECYCLE_PLEDGE_WALLET_FAILED,
		error,
		errorMessage,
	};
}

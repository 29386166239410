import { useRef, useEffect } from 'react';
import * as loadable from 'react-loadable';
import { Notify } from 'ibears-react-components';
import { IconLoading } from 'management-console/components/icon';
import { LoadingStatusEnum } from 'common-lib/enums';

const { LOADING, SUCCESS, FAILED } = LoadingStatusEnum;

const LoadingWrapper = prop => {
	const { error } = prop;

	if (process.env.IS_DEV && error) {
		console.error(error);
	}

	return IconLoading;
};

export function loadComponent(options) {
	return loadable({ loading: LoadingWrapper, ...options });
}

export function usePrevious(value) {
	const ref = useRef();

	useEffect(() => {
		ref.current = value;
	});

	return ref.current;
}

export function useLoadingStatus({
	loadingStatus,
	successMessage,
	failureMessage,
	onSuccess,
	onFailed,
	notifyDuration = 3000,
}) {
	const prevLoadingStatus = usePrevious(loadingStatus);

	useEffect(() => {
		if (LOADING === prevLoadingStatus && SUCCESS === loadingStatus) {
			if (successMessage && typeof successMessage === 'string') {
				Notify.successFill(successMessage, notifyDuration);
			}

			if (onSuccess && typeof onSuccess === 'function') {
				onSuccess();
			}
		} else if (LOADING === prevLoadingStatus && FAILED === loadingStatus) {
			if (failureMessage && typeof failureMessage === 'string') {
				Notify.errorFill(failureMessage, notifyDuration);
			}

			if (onFailed && typeof onFailed === 'function') {
				onFailed();
			}
		}
	}, [
		prevLoadingStatus,
		loadingStatus,
		successMessage,
		failureMessage,
		onSuccess,
		onFailed,
		notifyDuration,
	]);
}

import { ofType } from 'redux-observable';
import {
	switchMap,
	map,
	catchError,
} from 'rxjs/operators';
import { catchErrorForEpics } from 'common-lib/epic-utils';
import { rxjsApiFetcher } from 'client-console/lib/rxjs-api-utils';
import {
	actionTypes,
	marqueeActions,
	notifyActions,
} from 'client-console/controller';

const {
	START_FETCH_MARQUEES,
} = actionTypes;
const {
	fetchMarqueesSuccessAction,
	fetchMarqueesFailedAction,
} = marqueeActions;
const {
	notifyErrorAction,
} = notifyActions;

// GET /api/v1/marquees 跑馬燈列表
// https://app.gitbook.com/o/-LSmguN2bxAKDgeXkorc/s/ONMcUSpUdQyEDkSpslSO/client-api/888.025-marquee#pao-ma-deng-lie-biao
// eslint-disable-next-line import/prefer-default-export
export function fetchMarqueesEpic(action$) {
	return action$.pipe(
		ofType(START_FETCH_MARQUEES),
		switchMap(() => rxjsApiFetcher
			.get('marquees')
			.pipe(
				map(payload => payload.response),
				map(({ data }) => fetchMarqueesSuccessAction({ marquees: data })),
				catchError(error => catchErrorForEpics(error, fetchMarqueesFailedAction, notifyErrorAction)),
			),
		),
	);
}

import PropTypes from 'prop-types';
import cx from 'classnames';
import { TextButton } from 'ibears-react-components';
import { connect } from 'ljit-store-connecter';
import { LanguageCodeEnums } from 'common-lib/enums';
import { EarthSvg } from 'assets/index';
import { languageActions } from 'client-console/controller';
import './style.styl';

const { setLanguage } = languageActions;

const { ZH_CN, EN } = LanguageCodeEnums;
const NextLocaleTextMap = {
	[ZH_CN]: 'English',
	[EN]: '中文',
};

const PREFIX_CLASS = 'locale-button';

const propTypes = {
	className: PropTypes.string,

	language: PropTypes.string,
	setLanguage: PropTypes.func,
};

const LocaleButton = ({
	className,

	language,
	setLanguage,
}) => {
	const _renderText = () => (
		<>
			<EarthSvg/>
			<div>{NextLocaleTextMap[language]}</div>
		</>
	);

	const _handleChangeLocale = () => {
		if (language === ZH_CN) {
			setLanguage(EN);
		} else {
			setLanguage(ZH_CN);
		}
	};

	return (
		<TextButton
			text={_renderText()}
			className={cx(PREFIX_CLASS, className)}
			onClick={_handleChangeLocale}
		/>
	);
};

function mapStateToProps(state) {
	return {
		language: state.language.get('data'),
	};
}

function mapDispatchToProps(dispatch) {
	return {
		setLanguage: language => dispatch(setLanguage(language)),
	};
}

LocaleButton.propTypes = propTypes;

export default connect(mapStateToProps, mapDispatchToProps)(LocaleButton);

import { reLoginChecker, blockChecker } from './system-error-actions-utils';

const errorCheckers = [
	reLoginChecker,
	blockChecker,
];

export function getMessageFromResponse(payload, defaultMessage = '请稍后再试') {
	try {
		return payload.response.message;
	} catch (e) {
		console.log(e);
	}

	return defaultMessage;
}

export function catchErrorMessageForEpics(error, ...callbacks) {
	const errorMessage = getMessageFromResponse(error, '请稍后再试');

	return callbacks.map(callback => callback(error, errorMessage));
}

export function createErrorActions(error) {
	const actions = [];

	errorCheckers.forEach(errorChecker => {
		const action = errorChecker(error);

		if (typeof action === 'object' && action.type) {
			actions.push(action);
		}
	});

	if (!actions.length) {
		actions.push({
			type: 'AJAX_ERROR',
			error,
		});
	}

	return actions;
}

export function catchErrorForEpics(error, ...callbacks) {
	return [
		...createErrorActions(error),
		...catchErrorMessageForEpics(error, ...callbacks),
	];
}

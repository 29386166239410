import { useTranslation } from 'react-i18next';
import { TERSvg } from 'assets/index';
import './style.styl';

const PREFIX_CLASS = 'ter-topic';
const CONTENT_CLASS = `${PREFIX_CLASS}__content`;
const TITLE_CLASS = `${PREFIX_CLASS}__title`;
const SUB_TITLE_CLASS = `${PREFIX_CLASS}__sub-title`;

const TERTopic = () => {
	const { t } = useTranslation();

	return (
		<div className={PREFIX_CLASS}>
			<TERSvg/>
			<div className={CONTENT_CLASS}>
				<div className={TITLE_CLASS}>ter888.com</div>
				<div className={SUB_TITLE_CLASS}>{t('波场能量租赁平台')}</div>
			</div>
		</div>
	);
};

export default TERTopic;

export const TagGroupEnums = {
	DEFAULT: 0,
	PLEDGE_WALLET_INFO: 1,
};

export const StatusSwitchEnum = {
	ACTIVE: 1,
	BLOCKED: 2,
};

export const CollapseEnum = {
	HIDE: 0,
	SHOW: 1,
};

export const DetailsTypeEnum = {
	BASIC: 0,
	FULL: 1,
};

export const StatusEnum = {
	HANDLING: 1,
	SUCCESS: 2,
	FAILED: 3,
};

export const ManagementLogTypeEnum = {
	// 1. 個人帳號設定
	USERNAME_SETTING: 1,
	// 2. 帳號管理
	USERNAME_MANAGEMENT: 2,
	// 3. 角色管理
	ROLE_MANAGEMENT: 3,
	// 4. 角色商戶設定
	ROLE_BUSINESS_SETTING: 4,
	// 5. 角色權限設定
	ROLE_PERMISSION_SETTING: 5,
	// 6. 系統參數設定
	SYSTEM_ARGUMENTS_SETTING: 6,
	// 7. 质押钱包管理
	PLEDGE_WALLET_MANAGEMENT: 7,
	// 8. 商戶管理
	PLATFORM_MANAGEMENT: 8,
	// 9.專屬地址能量租賃
	RECEIVE_LOG_MANAGEMENT: 9,
	// 10. 能量派發排程
	ENERGY_RENT_JOB: 10,
	// 11. 能量回收排程
	ENERGY_COLLECT_JOB: 11,
	// 12. 退回虛擬幣排程
	ENERGY_BACK_JOB: 12,
	// 13. 手動派發能量
	MANUAL_RENT: 13,
	// 14. 手動派發能量排程
	MANUAL_ENERGY_RENT_JOB: 14,
	// 15. 手動派發能量回收排程
	MANUAL_ENERGY_COLLECT_JOB: 15,
	// 16. 系統谷歌設置
	SYSTEM_TOTP_SETTING: 16,
	// 17. 回收錢包設置
	COLLECT_WALLET_SETTING: 17,
	// 18. 预付能量派发排程
	PREPAID_RENT_JOB: 18,
	// 19. 预付能量回收排程
	PREPAID_COLLECT_JOB: 19,
	// 20. 换币转出钱包管理
	EXCHANGE_TRANSFER_OUT_WALLET: 20,
	// 21. 换币派发排程
	EXCHANGE_RENT_JOB: 21,
	// 22. 前台設定管理
	CLIENT_SETTING_MANAGEMENT: 22,
};

export const PledgeWalletOperationTypeEnum = {
	// 1.質押TRX
	FREEZE: 1,
	// 2.解鎖TRX
	UN_FREEZE: 2,
	// 3.提取TRX
	WITHDRAW: 3,
	// 4.回收代理TRX
	RECYCLE_AGENT: 4,
	// 5.入款
	DEPOSIT: 5,
	// 6.出款
	PAYMENT: 6,
	// 7.領取TRX獎勵
	GET_REWARD: 7,
};

export const PledgeWalletResourceTypeEnum = {
	// 1.寬帶
	BANDWIDTH: 1,
	// 2.能量
	ENERGY: 2,
};

export const WhetherEnum = {
	HAVE: true,
	NONE: false,
};

export const ReceiveLogStatusEnums = {
	// 處理中
	HANDLING: 1,
	// 超出上限
	EXCEEDED_LIMIT: 2,
	// 租賃中
	RENTING: 3,
	// 已租賃
	RENTED: 4,
	// 回收中
	COLLECTING: 5,
	// 已回收
	COLLECTED: 6,
	// 退回中
	BACKING: 7,
	// 退回失敗
	BACK_FAILED: 8,
	// 已退回
	BACKED: 9,
};

export const ReceiveLogCurrencyTypeEnums = {
	TRX: 1,
	USDT: 2,
};

export const ManualRentLogStatusEnums = {
	// 1.處理中
	HANDLING: 1,
	// 2.租賃中
	RENTING: 2,
	// 3.已租賃
	RENTED: 3,
	// 4.回收中
	COLLECTING: 4,
	// 5.已回收
	COLLECTED: 5,
};

export const CollectRentStatusEnums = {
	// 1.處理中
	HANDLING: 1,
	// 2.成功
	SUCCESS: 2,
	// 3.失敗
	FAILED: 3,
	// 4.超時失敗
	OVER_FAILED: 4,
};

export const PlatformApiLogTypeEnum = {
	// 1.單位能量請求
	UNIT_ENERGY_ASK: 1,
	// 2.能量請求記錄查詢
	ENERGY_ASK_LOG: 2,
	// 3.商戶餘額查詢
	PLATFORM_BALANCE: 3,
};

export const ResponseStatusEnum = {
	PROCESSING: 1,
	SUCCESS: 2,
	FAILED: 3,
	REQUEST_FAILED: 4,
};

export const ExchangeStatusEnum = {
	// 1.未達最小轉入U
	UN_ACHIEVED: 1,
	// 2.處理中
	HANDLING: 2,
	// 3.轉出中
	TRANSFERRING: 3,
	// 4.已完成
	COMPLETED: 4,
	// 5.超出最大轉入U
	OVERTAKE: 5,
};

export const TransferOutWalletOperationTypeEnum = {
	// 1.入款
	DEPOSIT: 1,
	// 2.出款
	PAYMENT: 2,
};

export const PlatformWalletCollectLogWalletTypeEnum = {
	// 收租錢包
	COLLECT_RENT: 1,
	// 換幣轉入錢包
	EXCHANGE_TRANSFER_IN: 2,
};

import { setLocalStorage } from 'common-lib/local-storage-utils';
import { actionTypes } from 'client-console/controller';

const { SET_LANGUAGE } = actionTypes;

const languageMiddleware = () => next => action => {
	const { type, language } = action;

	if (type === SET_LANGUAGE) {
		setLocalStorage('locale', language);
	}

	next(action);
};

export default languageMiddleware;

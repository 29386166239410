import PropTypes from 'prop-types';
import cx from 'classnames';
import { Modal } from 'ibears-react-components';

const PREFIX_CLASS = 'ter-client-modal';

const ModalWidthEnum = {
	SMALL: 320,
	NORMAL: 480,
	MIDDLE: 640,
	MEDIUM: 720,
	LARGE: 1024,
};

const propTypes = {
	className: PropTypes.string,
	width: PropTypes.number,
	children: PropTypes.node,
	visible: PropTypes.bool,
	onClickOk: PropTypes.func,
	onClickCancel: PropTypes.func,
};

const defaultProps = {
	width: ModalWidthEnum.SMALL,
	onClickOk: () => {},
	onClickCancel: () => {},
};

const ClientModal = ({
	className,
	width,
	children,
	visible,
	onClickOk,
	onClickCancel,
	...rest
}) => (
	<Modal
		isCentered
		isForceRender
		className={cx(PREFIX_CLASS, className)}
		width={width}
		visible={visible}
		onClickOk={onClickOk}
		onClickCancel={onClickCancel}
		{...rest}
	>
		{children}
	</Modal>
);

ClientModal.ModalWidthEnum = ModalWidthEnum;
ClientModal.propTypes = propTypes;
ClientModal.defaultProps = defaultProps;

export default ClientModal;

/* eslint-disable react/jsx-no-bind */
import PropTypes from 'prop-types';
import { omit } from 'lodash';
import qs from 'qs';
import {
	matchPath,
	Redirect,
	Switch,
	Route as ReactRoute,
} from 'react-router-dom';
import { Breadcrumb, HeaderBanner } from 'ibears-react-components';
import {
	paramReplace,
	getRouteMetaConfig,
} from './utils';

const propTypes = {
	path: PropTypes.string,
	exact: PropTypes.bool,
	strict: PropTypes.bool,
	component: PropTypes.any,
	paramProps: PropTypes.object,
	redirectPath: PropTypes.string,
	routes: PropTypes.arrayOf(PropTypes.object),
	meta: PropTypes.shape({
		breadcrumbName: PropTypes.oneOfType([
			PropTypes.string,
			PropTypes.node,
		]),
		isCrumbActive: PropTypes.bool,
		isCrumbVisible: PropTypes.bool,
		pageTitle: PropTypes.oneOfType([
			PropTypes.string,
			PropTypes.node,
		]),
		pageDescription: PropTypes.oneOfType([
			PropTypes.string,
			PropTypes.node,
		]),
	}),
	routePaths: PropTypes.array,
	extraProps: PropTypes.object,
};

const Route = props => {
	const routeProps = omit(props, [
		'routePaths',
		'extraProps',
		'component',
	]);
	const {
		extraProps,
		path,
		routes,
		paramProps,
		redirectPath,
		component: Page,
	} = props;
	const meta = { ...getRouteMetaConfig(props.meta) };

	const _renderReactRoute = matchProps => {
		const {
			match,
			history,
			location,
		} = matchProps;
		const { search } = location;
		const searchQuery = search ? qs.parse(search, { ignoreQueryPrefix: true }) : {};
		const mappedParamProps = {
			...searchQuery,
		};

		if (paramProps) {
			Object.keys(paramProps).forEach(key => {
				mappedParamProps[key] = paramReplace(paramProps[key], match.params);
			});
		}

		if (redirectPath) {
			if (matchPath(location.pathname, { path, exact: true })) {
				return <Redirect to={paramReplace(redirectPath, match.params)}/>;
			}
		}

		function onNavigate(uri, options = { passProps: {} }) {
			history.push({
				pathname: uri,
				passProps: options.passProps,
			});
		}

		function onBack() {
			history.goBack();
		}

		return (
			<>
				<Breadcrumb.BreadcrumbItem
					to={matchProps.match.url}
					isActive={meta.isCrumbActive}
					isVisible={meta.isCrumbVisible}
				>
					{paramReplace(meta.breadcrumbName, match.params)}
				</Breadcrumb.BreadcrumbItem>
				<HeaderBanner.HeaderBannerItem
					title={paramReplace(meta.pageTitle || meta.breadcrumbName, match.params)}
					description={meta.pageDescription}
					isBannerVisible={meta.isBannerVisible}
				/>
				<Page
					{...extraProps}
					{...location.passProps}
					{...mappedParamProps}
					renderedRoutes={renderSwitches(routes, extraProps)}
					pathName={location.pathname}
					onNavigate={onNavigate}
					onBack={onBack}
				/>
			</>
		);
	};

	return (
		<ReactRoute
			{...routeProps}
			render={_renderReactRoute}
		/>
	);
};

Route.propTypes = propTypes;

export default Route;

const renderRoutes = (routePaths, routes, extraProps = {}) => (
	routes.map((route, i) => (
		<Route
			key={route.key || i}
			path={route.path}
			exact={route.exact}
			strict={route.strict}
			component={route.component}
			paramProps={route.paramProps}
			redirectPath={route.redirectPath}
			routes={route.routes}
			meta={route.meta}
			extraProps={extraProps}
			routePaths={routePaths}
		/>
	))
);

export const renderSwitches = (routes, extraProps = {}, switchProps = {}) => (
	routes ? (
		<Switch {...switchProps}>
			{renderRoutes([], routes, extraProps)}
		</Switch>
	) : null
);

import i18n from 'i18next';
import { initReactI18next, useTranslation } from 'react-i18next';
import { LanguageCodeEnums } from '../enums';
import cn from './locales/zh-CN';
import en from './locales/en';

const {
	ZH_CN,
	EN,
} = LanguageCodeEnums;
const LanguageSourceMap = {
	[ZH_CN]: {
		translation: cn,
	},
	[EN]: {
		translation: en,
	},
};
const DEFAULT_LOCALE = ZH_CN;
const i18nLanguageCodeMap = {
	'zh-cn': ZH_CN,
	'zh-CN': ZH_CN,
	'zh-TW': ZH_CN,
	zh: ZH_CN,
	zhCN: ZH_CN,
	en: EN,
	ZH_CN,
	EN,
};
const StyleLanguageClassNameEnums = {
	[ZH_CN]: `ter-${ZH_CN}`,
	[EN]: `ter-${EN}`,
};

function initI18n(locale = DEFAULT_LOCALE) {
	const languageCode = i18nLanguageCodeMap[locale];

	return i18n
		.use(initReactI18next)
		.init({
			resources: LanguageSourceMap,
			fallbackLng: DEFAULT_LOCALE,
			lng: languageCode,
			lowerCaseLng: true,
		}, () => {
			changeLanguage(languageCode);
		});
}

function changeStyleLanguage(languageCode) {
	document.querySelector('body').className = StyleLanguageClassNameEnums[languageCode];
}

function changeI18nLanguage(languageCode) {
	return i18n.changeLanguage(languageCode);
}

function changeLanguage(locale) {
	const languageCode = i18nLanguageCodeMap[locale];

	changeStyleLanguage(languageCode);
	changeI18nLanguage(languageCode);
}

function getTranslation(word, options) {
	return i18n.t(word, options);
}

/**
Example:
t(
	'每過 <<counter>> 分鐘, 這世界就流失 <<counter2>> 秒鐘',
	{},
	{
		counter: <span>1</span>,
		counter2: <span>60</span>,
	},
)
*/

function useCustomTranslation() {
	const { t, ...args } = useTranslation();
	const tWithCustomComponent = (key, interpolation, components) => {
		const translatedText = t(key, interpolation);

		if (components) {
			const parts = translatedText.split(/(<<\w+>>)/).map(part => {
				const match = part.match(/<<(\w+)>>/);

				if (match) {
					const componentKey = match[1];

					return <span key={componentKey}>{components[componentKey]}</span> || match[0];
				}

				return part;
			});

			return <div>{parts}</div>;
		}

		return translatedText;
	};

	return { t: tWithCustomComponent, ...args };
}

export {
	DEFAULT_LOCALE,
	i18nLanguageCodeMap,
	initI18n,
	changeLanguage,
	getTranslation,
	useCustomTranslation,
};

import { WindowSizeEnum } from 'common-lib/enums';

const {
	MOBILE,
	TABLET,
	DESKTOP,
	LG_DESKTOP,
} = WindowSizeEnum;

export const SectionIdEnums = {
	ENERGY: 'energy',
	EXCHANGE: 'exchange',
	RECORD: 'record',
	PLATFORM: 'platform',
	QUESTIONS: 'questions',
	SERVICE: 'service',
};

export const LatestReceivingRecordsTypeEnum = {
	USDT_TO_TRX: 1,
	TRX_TO_ENERGY: 2,
};

export const DevicesNameEnum = {
	[MOBILE]: 'Mobile',
	[TABLET]: 'Tablet',
	[DESKTOP]: 'Desktop',
	[LG_DESKTOP]: 'LG_Desktop',
};

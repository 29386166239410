import { ofType } from 'redux-observable';
import {
	switchMap,
	map,
	catchError,
} from 'rxjs/operators';
import { catchErrorForEpics } from 'common-lib/epic-utils';
import { rxjsApiFetcher } from 'client-console/lib/rxjs-api-utils';
import {
	actionTypes,
	platformWalletActions,
	notifyActions,
} from 'client-console/controller';

const {
	START_FETCH_PLATFORM_WALLETS,
	START_FETCH_TRANSFER_IN_WALLET,
} = actionTypes;
const {
	fetchPlatformWalletsSuccessAction,
	fetchPlatformWalletsFailedAction,
	fetchTransferInWalletSuccessAction,
	fetchTransferInWalletFailedAction,
} = platformWalletActions;
const {
	notifyErrorAction,
} = notifyActions;

// GET /api/v1/platforms/name=ter888/wallets
// https://app.gitbook.com/o/-LSmguN2bxAKDgeXkorc/s/ONMcUSpUdQyEDkSpslSO/client-api/888.002-platform
export function fetchPlatformWalletsEpic(action$) {
	return action$.pipe(
		ofType(START_FETCH_PLATFORM_WALLETS),
		switchMap(() => rxjsApiFetcher
			.get('platforms/name=ter888/wallets')
			.pipe(
				map(payload => payload.response),
				map(({ data }) => fetchPlatformWalletsSuccessAction({ wallets: data })),
				catchError(error => catchErrorForEpics(error, fetchPlatformWalletsFailedAction, notifyErrorAction)),
			),
		),
	);
}

// GET /api/v1/platforms/name=ter888/exchange-transfer-in-wallets
// https://app.gitbook.com/o/-LSmguN2bxAKDgeXkorc/s/ONMcUSpUdQyEDkSpslSO/client-api/888.002-platform
export function fetchTransferINWalletEpic(action$) {
	return action$.pipe(
		ofType(START_FETCH_TRANSFER_IN_WALLET),
		switchMap(() => rxjsApiFetcher
			.get('platforms/name=ter888/exchange-transfer-in-wallets')
			.pipe(
				map(payload => payload.response),
				map(data => fetchTransferInWalletSuccessAction({ wallet: data })),
				catchError(error => catchErrorForEpics(error, fetchTransferInWalletFailedAction, notifyErrorAction)),
			),
		),
	);
}

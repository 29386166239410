import { connect } from 'react-redux';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Breadcrumb } from 'ibears-react-components';
import { initController } from 'ljit-store-connecter';
import { createStore, StoreProvider } from './repositories';
import App from './app';

const container = document.getElementById('client-console-root');
const root = createRoot(container);

const basename = process.env.CLIENT_BASENAME;
const store = createStore();

initController(store, connect);

root.render(
	<StoreProvider store={store}>
		<BrowserRouter basename={basename}>
			<Breadcrumb.BreadcrumbProvider>
				<App/>
			</Breadcrumb.BreadcrumbProvider>
		</BrowserRouter>
	</StoreProvider>,
);

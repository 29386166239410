import { combineEpics } from 'redux-observable';
import * as marqueeEpics from './marquee-epics';
import * as exchangeSettingEpics from './exchange-setting-epics';
import * as exchangeRateEpics from './exchange-rate-epics';
import * as platformWalletEpics from './platform-wallet-epics';
import * as latestReceiveLogsEpics from './latest-receive-logs-epics';
import * as csSettingEpics from './cs-setting-epics';

const epics = combineEpics(
	...Object.values(marqueeEpics),
	...Object.values(exchangeSettingEpics),
	...Object.values(exchangeRateEpics),
	...Object.values(platformWalletEpics),
	...Object.values(latestReceiveLogsEpics),
	...Object.values(csSettingEpics),
);

export default epics;

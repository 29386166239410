/* eslint-disable no-useless-computed-key */
export default {
	['{{text}}标题']: '{{-text}}标题',
	['最新接收记录']: '最新接收记录',
	['接收地址']: '接收地址',
	['兑换类型']: '兑换类型',
	['接收数量']: '接收数量',
	['ter888.com 平台介绍']: 'ter888.com 平台介绍',
	['经济']: '经济',
	['安全']: '安全',
	['高效']: '高效',
	['ter888.com平台是波场生态中的能量兑换平台。我们的宗旨是为波场交易者降低能量消耗所需TRX费用。兑换流程简单快捷，价格优惠，24小时为您提供更安全且高效的能量兑换服务。']: 'ter888.com平台是波场生态中的能量兑换平台。我们的宗旨是为波场交易者降低能量消耗所需TRX费用。兑换流程简单快捷，价格优惠，24小时为您提供更安全且高效的能量兑换服务。',
	['解决 TRX 手续费过高问题']: '解决 TRX 手续费过高问题',
	['快捷方便，只需转账即可兑换能量']: '快捷方便，只需转账即可兑换能量',
	['适用 USDT、合约转账、部署合约等']: '适用 USDT、合约转账、部署合约等',
	['Tron 波场用户必备省钱专家']: 'Tron 波场用户必备省钱专家',
	['波场能量租赁平台']: '波场能量租赁平台',
	['能量兑换']: '能量兑换',
	['数字币兑换']: '数字币兑换',
	['平台介绍']: '平台介绍',
	['常见问题']: '常见问题',
	['在线客服']: '在线客服',
	['TRON 转账次数购买']: 'TRON 转账次数购买',
	['简单方便 帮你省钱']: '简单方便 帮你省钱',
	['能量兑换 - 操作说明']: '能量兑换 - 操作说明',
	['请使用去中心化钱包租赁能量。']: '请使用去中心化钱包租赁能量。',
	['根据能量的租赁需求，系统会计算出兑换总量和需支付金额。支持TRX支付。']: '根据能量的租赁需求，系统会计算出兑换总量和需支付金额。支持TRX支付。',
	['转账完成后，平台将派发对应的能量到您的支付账户中。']: '转账完成后，平台将派发对应的能量到您的支付账户中。',
	['提倡按需租赁，用完再来租！一天不限制租赁次数，一次租赁最多兑换10笔能量！']: '提倡按需租赁，用完再来租！一天不限制租赁次数，一次租赁最多兑换10笔能量！',
	['填写需求']: '填写需求',
	['转账笔数']: '转账笔数',
	['笔']: '笔',
	['单价']: '单价',
	['小时']: '小时',
	['请填写您预计每日需转账的次数，系统将自动估算出每日需消耗的能量。']: '请填写您预计每日需转账的次数，系统将自动估算出每日需消耗的能量。',
	['每笔等于 <<inputNumber>> 能量']: '每笔等于 <<inputNumber>> 能量',
	['当前能量池可购买余额']: '当前能量池可购买余额',
	['支付']: '支付',
	['需转账金额']: '需转账金额',
	['限时特惠']: '限时特惠',
	['较使用TRX转账，节省约']: '较使用TRX转账，节省约',
	['平台收款地址']: '平台收款地址',
	['数字币兑换（USDT兑换TRX）']: '数字币兑换（USDT兑换TRX）',
	['兑换数量 = （接收数量 × 市场汇率 - 油费）']: '兑换数量 = （接收数量 × 市场汇率 - 油费）',
	['兑换说明：']: '兑换说明：',
	['您只需要将USDT转到平台账户地址，平台收到您的转账后将自动给你转会对应数量的TRX币。注意！最小{{min}}U起兑，最大{{max}}U！如需兑换更大金额请联系客服。']: '您只需要将USDT转到平台账户地址，平台收到您的转账后将自动给你转会对应数量的TRX币。注意！最小{{min}}U起兑，最大{{max}}U！如需兑换更大金额请联系客服。',
	['平台收款二维码']: '平台收款二维码',
	['保存到本地']: '保存到本地',
	['有碰到任何问题，欢迎咨询在线客服']: '有碰到任何问题，欢迎咨询在线客服',
	['点击复制']: '点击复制',
	['FAQ 常见问题']: 'FAQ 常见问题',
	['如何购买次数']: '如何购买次数',
	['请在本页站头部中填写购买信息，然后根据提示向平台账户转入对应的TRX即可。']: '请在本页站头部中填写购买信息，然后根据提示向平台账户转入对应的TRX即可。',
	['支付成功后，能量什么时候到账？']: '支付成功后，能量什么时候到账？',
	['付款完成后，正常情况下能量会在30秒内到账。由于广播延迟等原因，极少数情况，会存在30到60秒的延迟。若没收到能量，请联系客服处理']: '付款完成后，正常情况下能量会在30秒内到账。由于广播延迟等原因，极少数情况，会存在30到60秒的延迟。若没收到能量，请联系客服处理',
	['同一接收地址同时可以几笔订单？']: '同一接收地址同时可以几笔订单？',
	['同一地址也可以多笔购买，不限制使用次数。']: '同一地址也可以多笔购买，不限制使用次数。',
	['订单可以撤回吗？']: '订单可以撤回吗？',
	['当您向平台账户支付费用后，订单不支持撤回。']: '当您向平台账户支付费用后，订单不支持撤回。',
	['下载失败']: '下载失败',
	['能量兑换维护中']: '能量兑换维护中',
	['数字币兑换维护中']: '数字币兑换维护中',
	['TRON能量租赁平台']: 'TRON能量租赁平台',
};

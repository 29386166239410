import {
	SHOW_RELOGIN_MESSAGE,

	START_VALIDATE_CLIENT_IP,
	VALIDATE_CLIENT_IP_SUCCESS,
	VALIDATE_CLIENT_IP_FAILED,
} from './action-types';

export function showReloginMessageAction(message) {
	return {
		type: SHOW_RELOGIN_MESSAGE,
		message,
	};
}

export function startValidateClientIpAction() {
	return {
		type: START_VALIDATE_CLIENT_IP,
	};
}

export function validateClientIpSuccessAction() {
	return {
		type: VALIDATE_CLIENT_IP_SUCCESS,
	};
}

export function validateClientIpFailedAction() {
	return {
		type: VALIDATE_CLIENT_IP_FAILED,
	};
}

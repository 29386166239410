import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'ljit-store-connecter';
import { LoadingStatusEnum } from 'common-lib/enums';
import copy from 'common-lib/copy-to-clipboard';
import ClientModal from 'client-console/components/client-modal';
import { TelegramSvg, ICCopyWhite } from 'assets/index';
import {
	csSettingActions,
	contactActions,
} from 'client-console/controller';
import './style.styl';

const { fetchCSSettingAction } = csSettingActions;
const { hideContactModal } = contactActions;

const { LOADING } = LoadingStatusEnum;

const PREFIX_CLASS = 'service-modal';
const DESC_CLASS = `${PREFIX_CLASS}__desc`;
const ICON_CLASS = `${PREFIX_CLASS}__icon`;
const LINK_CLASS = `${PREFIX_CLASS}__link`;
const BTN_CLASS = `${PREFIX_CLASS}__btn`;

const renderTelegramLink = account => {
	if (!account) {
		return '--';
	}

	const url = `https://t.me/${account.replace(/^@/, '')}`;

	return <a href={url} rel="noopener noreferrer" target="_blank">{account}</a>;
};

const propTypes = {
	isVisible: PropTypes.bool.isRequired,
	onCancel: PropTypes.func,

	csConfigMap: PropTypes.object,
	loadingStatus: PropTypes.oneOf(Object.values(LoadingStatusEnum)),

	fetchCSSettingAction: PropTypes.func,
	hideContactModal: PropTypes.func,
};

const defaultProps = {
	onCancel: () => {},
};

const ServiceModal = ({
	isVisible,
	onCancel,

	csConfigMap,
	loadingStatus,
	fetchCSSettingAction,
	hideContactModal,
}) => {
	const { t } = useTranslation();
	const telegramAccount = csConfigMap.get('telegramAccount');
	const isLoading = loadingStatus === LOADING;

	useEffect(() => {
		if (isVisible) {
			fetchCSSettingAction();
		}
	}, [isVisible, fetchCSSettingAction]);

	const _handleCopy = () => {
		copy(telegramAccount);
	};

	const _renderCopyBtn = () => {
		if (isLoading || !telegramAccount) {
			return null;
		}

		return (
			<div className={BTN_CLASS}>
				<button type="button" onClick={_handleCopy}>
					<ICCopyWhite/>
					<div>{t('点击复制')}</div>
				</button>
			</div>
		);
	};

	return (
		<ClientModal
			isCentered
			className={PREFIX_CLASS}
			visible={isVisible}
			title={t('在线客服')}
			onClickCancel={() => {
				hideContactModal();
				onCancel();
			}}
		>
			<div className={DESC_CLASS}>{t('有碰到任何问题，欢迎咨询在线客服')}</div>
			<div className={ICON_CLASS}><TelegramSvg/></div>
			<div className={LINK_CLASS}>
				{renderTelegramLink(telegramAccount)}
			</div>
			{_renderCopyBtn()}
		</ClientModal>
	);
};

function mapStateToProps(state) {
	const csSettingReducer = state.csSetting;

	return {
		csConfigMap: csSettingReducer.get('csConfigMap'),
		loadingStatus: csSettingReducer.get('loadingStatus'),
	};
}

function mapDispatchToProps(dispatch) {
	return {
		fetchCSSettingAction: language => dispatch(fetchCSSettingAction(language)),
		hideContactModal: () => dispatch(hideContactModal()),
	};
}

ServiceModal.propTypes = propTypes;
ServiceModal.defaultProps = defaultProps;

export default connect(mapStateToProps, mapDispatchToProps)(ServiceModal);

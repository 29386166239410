// API handler
export const AJAX_ERROR = 'AJAX_ERROR';

// Language
export const SET_LANGUAGE = 'SET_LANGUAGE';

// Marquees
export const START_FETCH_MARQUEES = 'START_FETCH_MARQUEES';
export const FETCH_MARQUEES_SUCCESS = 'FETCH_MARQUEES_SUCCESS';
export const FETCH_MARQUEES_FAILED = 'FETCH_MARQUEES_FAILED';

// Exchange Settings
export const START_FETCH_EXCHANGE_SETTING = 'START_FETCH_EXCHANGE_SETTING';
export const FETCH_EXCHANGE_SETTING_SUCCESS = 'FETCH_EXCHANGE_SETTING_SUCCESS';
export const FETCH_EXCHANGE_SETTING_FAILED = 'FETCH_EXCHANGE_SETTING_FAILED';

// Exchange Rate
export const START_FETCH_EXCHANGE_RATE = 'START_FETCH_EXCHANGE_RATE';
export const FETCH_EXCHANGE_RATE_SUCCESS = 'FETCH_EXCHANGE_RATE_SUCCESS';
export const FETCH_EXCHANGE_RATE_FAILED = 'FETCH_EXCHANGE_RATE_FAILED';

// Platform Wallet
export const START_FETCH_PLATFORM_WALLETS = 'START_FETCH_PLATFORM_WALLETS';
export const FETCH_PLATFORM_WALLETS_SUCCESS = 'FETCH_PLATFORM_WALLETS_SUCCESS';
export const FETCH_PLATFORM_WALLETS_FAILED = 'FETCH_PLATFORM_WALLETS_FAILED';

export const START_FETCH_TRANSFER_IN_WALLET = 'START_FETCH_TRANSFER_IN_WALLET';
export const FETCH_TRANSFER_IN_WALLET_SUCCESS = 'FETCH_TRANSFER_IN_WALLET_SUCCESS';
export const FETCH_TRANSFER_IN_WALLET_FAILED = 'FETCH_TRANSFER_IN_WALLET_FAILED';

// Latest Receive Logs
export const START_FETCH_LATEST_RECEIVE_LOGS = 'START_FETCH_LATEST_RECEIVE_LOGS';
export const FETCH_LATEST_RECEIVE_LOGS_SUCCESS = 'FETCH_LATEST_RECEIVE_LOGS_SUCCESS';
export const FETCH_LATEST_RECEIVE_LOGS_FAILED = 'FETCH_LATEST_RECEIVE_LOGS_FAILED';

// CS Settings
export const START_FETCH_CS_SETTING = 'START_FETCH_CS_SETTING';
export const FETCH_CS_SETTING_SUCCESS = 'FETCH_CS_SETTING_SUCCESS';
export const FETCH_CS_SETTING_FAILED = 'FETCH_CS_SETTING_FAILED';

// Contact
export const SHOW_CONTACT_MODAL = 'SHOW_CONTACT_MODAL';
export const HIDE_CONTACT_MODAL = 'HIDE_CONTACT_MODAL';

// Web head source
export const INIT_WEB_HEAD_SOURCE = 'INIT_WEB_HEAD_SOURCE';

export const SET_WEB_DEFAULT_NICKNAME = 'SET_WEB_DEFAULT_NICKNAME';

export const SET_WEB_NICKNAME = 'SET_WEB_NICKNAME';
export const RESET_WEB_NICKNAME = 'RESET_WEB_NICKNAME';

export const SET_WEB_DESCRIPTION = 'SET_WEB_DESCRIPTION';
export const RESET_WEB_DESCRIPTION = 'RESET_WEB_DESCRIPTION';

export const SET_WEB_STRUCTURED_DATA = 'SET_WEB_STRUCTURED_DATA';
export const RESET_WEB_STRUCTURED_DATA = 'RESET_WEB_STRUCTURED_DATA';


import { Icon } from 'ibears-react-components';
import { ICCopyGreen } from 'assets/index';

const {
	CHECK_CIRCEL_FILL,
	LOADING_OUTLINED: LOADING,
} = Icon.IconTypeEnums;

const {
	SUCCESS,
} = Icon.ColorEnums;

const {
	SMALL,
	LARGE,
} = Icon.SizeEnums;

const IconLoading = <Icon spin type={LOADING} size={SMALL}/>;
const IconSuccessFill = <Icon type={CHECK_CIRCEL_FILL} color={SUCCESS}/>;
const IconCopyGreen = <Icon component={ICCopyGreen} size={LARGE}/>;

export {
	IconLoading,
	IconSuccessFill,
	IconCopyGreen,
};

/* eslint-disable no-useless-computed-key */
export default {
	['{{text}}标题']: '{{text}}Title',
	['最新接收记录']: 'Latest transaction',
	['接收地址']: 'receiving address',
	['兑换类型']: 'exchange type',
	['接收数量']: 'receive quantity',
	['ter888.com 平台介绍']: 'ter888.com Platform Introduction',
	['经济']: 'ECONOMIC',
	['安全']: 'SAFETY',
	['高效']: 'EFFICIENT',
	['ter888.com平台是波场生态中的能量兑换平台。我们的宗旨是为波场交易者降低能量消耗所需TRX费用。兑换流程简单快捷，价格优惠，24小时为您提供更安全且高效的能量兑换服务。']: 'The ter888.com platform is an energy exchange platform in wave field ecology. Our goal is to reduce the TRX fee required for wave traders to consume energy. The exchange process is simple and fast, with a favorable price. We provide you with a safer and more efficient energy exchange service 24 hours a day.',
	['解决 TRX 手续费过高问题']: 'Solve the problem of excessive TRX fees',
	['快捷方便，只需转账即可兑换能量']: 'Fast and convenient',
	['适用 USDT、合约转账、部署合约等']: 'Applicable to USDT, contract transfer, etc',
	['Tron 波场用户必备省钱专家']: 'Money Saving Expert for Tron Users',
	['波场能量租赁平台']: 'TRON Energy Rental Platform',
	['能量兑换']: 'Energy exchange',
	['数字币兑换']: 'Currency exchange',
	['平台介绍']: 'Platform Introduction',
	['常见问题']: 'FAQ',
	['在线客服']: 'Support',
	['TRON 转账次数购买']: 'TRON Transfer Times Purchase',
	['简单方便 帮你省钱']: 'Reasonable price, real-time transfer',
	['能量兑换 - 操作说明']: 'Instructions',
	['请使用去中心化钱包租赁能量。']: 'Please use decentralized wallet to rent energy.',
	['根据能量的租赁需求，系统会计算出兑换总量和需支付金额。支持TRX支付。']: 'According to the requirements, the system accountant calculates the total amount and amount. Support TRX payments.',
	['转账完成后，平台将派发对应的能量到您的支付账户中。']: 'After payment, the platform will distribute the corresponding energy to your account',
	['提倡按需租赁，用完再来租！一天不限制租赁次数，一次租赁最多兑换10笔能量！']: 'Advocate for on-demand leasing and use it again! Unlimited number of transactions per day. A maximum of 10 unit of energy per transaction!',
	['填写需求']: 'Fill in quantity',
	['转账笔数']: 'Number of transfers',
	['笔']: '',
	['单价']: '',
	['小时']: 'hours',
	['请填写您预计每日需转账的次数，系统将自动估算出每日需消耗的能量。']: 'Fill in your estimated daily number of times transactions and the system will automatically estimate the daily energy consumption.',
	['每笔等于 <<inputNumber>> 能量']: ' <<inputNumber>> energy per transaction',
	['当前能量池可购买余额']: 'Remaining energy of the platform',
	['支付']: 'Payment',
	['需转账金额']: 'Amount',
	['限时特惠']: 'flash sale',
	['较使用TRX转账，节省约']: 'Save approximately',
	['平台收款地址']: 'Platform billing address',
	['数字币兑换（USDT兑换TRX）']: 'Token Exchange (USDT>TRX)',
	['兑换数量 = （接收数量 × 市场汇率 - 油费）']: 'Exchange Quantity = (Received Quantity × Exchange Rate - Gas fee)',
	['兑换说明：']: 'Instructions:',
	['您只需要将USDT转到平台账户地址，平台收到您的转账后将自动给你转会对应数量的TRX币。注意！最小{{min}}U起兑，最大{{max}}U！如需兑换更大金额请联系客服。']: 'You only need to transfer USDT to the platform account address, and the platform will automatically transfer you the corresponding amount of TRX coins after receiving your transfer. Attention! The minimum of transaction will be {{min}}U and the maximum will be {{max}}U. If you need to transfer a larger amount please contact the Support.',
	['平台收款二维码']: 'QR code',
	['保存到本地']: 'Save to local',
	['有碰到任何问题，欢迎咨询在线客服']: 'Please contact us if you have any further questions.',
	['点击复制']: 'Copy',
	['FAQ 常见问题']: 'FAQ',
	['如何购买次数']: 'How to purchase?',
	['请在本页站头部中填写购买信息，然后根据提示向平台账户转入对应的TRX即可。']: 'Fill in your estimated daily needs of transactions and transfer TRX payment to designation billing address.',
	['支付成功后，能量什么时候到账？']: 'When will the energy arrive?',
	['付款完成后，正常情况下能量会在30秒内到账。由于广播延迟等原因，极少数情况，会存在30到60秒的延迟。若没收到能量，请联系客服处理']: 'After the payment is completed,the energy will be received within 30 second, but here will be a delay of 30 to 60 seconds under a few condition.',
	['同一接收地址同时可以几笔订单？']: 'How many orders can I have at the same time?',
	['同一地址也可以多笔购买，不限制使用次数。']: 'Multiple purchases can be made under the same address, with no limits.',
	['订单可以撤回吗？']: 'Can I withdraw my order?',
	['当您向平台账户支付费用后，订单不支持撤回。']: 'The order does not support withdrawal.',
	['下载失败']: 'failed to download',
	['能量兑换维护中']: 'Energy exchange is under maintenance',
	['数字币兑换维护中']: 'Currency exchange is under maintenance',
	['TRON能量租赁平台']: 'TRON Energy Rental Platform',
};

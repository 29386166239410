import NotFoundSvg from './icon/img-404.svg';
import QuestionSvg from './icon/question.svg';
import ICCopyGreen from './icon/ic-copy-green.svg';

// Sidebar
import SidebarSystemSettingSvg from './sidebar-icon/system-setting.svg';
import SystemSvg from './sidebar-icon/system.svg';
import TransactionInfoSvg from './sidebar-icon/transaction-info.svg';
import EnergyRentalManagementSvg from './sidebar-icon/energy-rental-management.svg';
import FrontDeskSettingManagementSvg from './sidebar-icon/front-desk-setting-management.svg';

// Client
import LoadingSvg from './client-icon/loading.svg';

import TERSvg from './client-icon/ter.svg';
import EarthSvg from './client-icon/earth.svg';
import HamburgerSvg from './client-icon/hamburger.svg';
import ServiceWebp from './client-icon/service.webp'; // 轉檔取得
import TelegramSvg from './client-icon/telegram.svg';
import ICCopyWhite from './client-icon/ic-copy-white.svg';
import ICToTop from './client-icon/ic-to-top.svg';
import MaintainWebp from './client-icon/maintain.webp'; // 轉檔取得

import NumOneSvg from './client-icon/num-one.svg';
import NumTwoSvg from './client-icon/num-two.svg';
import ICDotBrownSvg from './client-icon/ic-dot-brown.svg';
import ICCopyYellow from './client-icon/ic-copy-yellow.svg';
import ICQuestion from './client-icon/ic-question.svg';
import ICQrCode from './client-icon/ic-qr-code.svg';

import ArrowTransferSvg from './client-icon/arrow-transfer.svg';

import ArrowDecorationSvg from './client-icon/arrow-decoration.svg';
import StreamerDecorationLgSvg from './client-icon/streamer-decoration-lg.svg';
import StreamerDecorationSmSvg from './client-icon/streamer-decoration-sm.svg';

import CoinSvg from './client-icon/coin.svg';
import LockSvg from './client-icon/lock.svg';
import ThunderSvg from './client-icon/thunder.svg';
import FireSvg from './client-icon/fire.svg';

import BagSvg from './client-icon/bag.svg';
import ReceiveCoinSvg from './client-icon/receive-coin.svg';
import LocationSvg from './client-icon/location.svg';
import FallbackSvg from './client-icon/fallback.svg';

export {
	NotFoundSvg,
	QuestionSvg,
	ICCopyGreen,

	SidebarSystemSettingSvg,
	SystemSvg,
	TransactionInfoSvg,
	EnergyRentalManagementSvg,
	FrontDeskSettingManagementSvg,

	LoadingSvg,

	TERSvg,
	EarthSvg,
	HamburgerSvg,
	ServiceWebp,
	TelegramSvg,
	ICCopyWhite,
	ICToTop,
	MaintainWebp,

	NumOneSvg,
	NumTwoSvg,
	ICDotBrownSvg,
	ICCopyYellow,
	ICQuestion,
	ICQrCode,

	ArrowTransferSvg,

	ArrowDecorationSvg,
	StreamerDecorationLgSvg,
	StreamerDecorationSmSvg,

	CoinSvg,
	LockSvg,
	ThunderSvg,
	FireSvg,

	BagSvg,
	ReceiveCoinSvg,
	LocationSvg,
	FallbackSvg,
};

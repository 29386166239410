import PropTypes from 'prop-types';
import cx from 'classnames';
import NavList from 'client-console/components/nav-list';
import Logo from 'client-console/components/logo';
import LocaleButton from 'client-console/features/locale-button';
import { HamburgerSvg } from 'assets/index';
import './style.styl';

const PREFIX_CLASS = 'hamburger';
const ICON_CLASS = `${PREFIX_CLASS}__icon`;
const MASK_CLASS = `${PREFIX_CLASS}__mask`;

const SIDEBAR_CLASS = `${PREFIX_CLASS}__sidebar`;
const MENU_CLASS = `${SIDEBAR_CLASS}__menu`;

const propTypes = {
	navItems: PropTypes.array,
	isOpen: PropTypes.bool,
	onToggle: PropTypes.func,
	onClickNavItem: PropTypes.func,
};

const Hamburger = ({
	navItems,
	isOpen,
	onToggle,
	onClickNavItem,
}) => (
	<div className={PREFIX_CLASS}>
		<div className={ICON_CLASS} onClick={onToggle}>
			<HamburgerSvg/>
		</div>
		<div className={cx(MASK_CLASS, isOpen && 'open')} onClick={onToggle}/>
		<div className={cx(SIDEBAR_CLASS, isOpen && 'show')}>
			<div className={MENU_CLASS}>
				<Logo onClick={onToggle}/>
				<NavList
					navItems={navItems}
					onClickNavItem={navId => {
						onToggle();
						onClickNavItem(navId);
					}}
				/>
				<LocaleButton/>
			</div>
		</div>
	</div>
);

Hamburger.propTypes = propTypes;

export default Hamburger;
